import { DataSet } from "../DataSet";
import { RangeFilter } from "../RangeFilter";
import { TableColumn } from "../TableColumn";
export class DomainCategoryCompanyUrls extends DataSet {
    constructor(resultSet, domain, company, category) {
        super();
        /**
         * Expected format for result set
         * [{"url": "<string>", "title": "<string>", "score": <int>}]
         */
        this.urls = [];
        this.company = '';
        this.category = '';
        this.setResultSet(resultSet);
        this.setDomain(domain);
        this.setCompany(company);
        this.setCategory(category);
        this.initialize();
    }
    initialize() {
        this.setDataTypeBasic('urls');
        this.setDataTypeFull('domain_category_company_urls');
        this.setDefaultSortColumn('score');
        this.setDefaultSortDirection('desc');
        let urls = this.getResultSet()
            .filter(c => c.url !== '')
            .map(v => {
            return { url: v.url, title: v.title, score: this.truncateScore(v.score) };
        });
        this.setUrls(urls);
        this.setTitle('URLs for ' + this.getDomain() + ' - ' + this.getCompany() + ' - ' + this.getCategory());
        this.setRowIdentifier('url');
        this.setIsSearchable(true);
        this.addSearchableField('url');
        this.createRangeFilters();
        this.createTableColumns();
        this.setTableRows(this.urls);
        this.setSearchClass('col-md-6');
        this.setRangeSliderClass('col-md-6');
    }
    createRangeFilters() {
        let min = Math.min.apply(Math, this.urls.map(c => c.score));
        let max = Math.max.apply(Math, this.urls.map(c => c.score));
        if (min === max) {
            return;
        }
        let filter = new RangeFilter();
        filter.setField('score');
        filter.setMax(max);
        filter.setMin(min);
        this.addRangeFilter(filter);
    }
    createTableColumns() {
        let tableColumn = new TableColumn();
        tableColumn.setRowValueIsExternalClickable(true);
        tableColumn.setRowValueExternalClickableLinkRowValue('url');
        tableColumn.setRowValueTooltip('Visit');
        tableColumn.setRowValueClickIcon('external-link-alt');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderValue('title');
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setRowValue('title');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('score');
        tableColumn.setRowValue('score');
        this.addTableColumn(tableColumn);
    }
    getUrls() {
        return this.urls.map(a => (Object.assign({}, a)));
    }
    setUrls(urls) {
        this.urls = urls;
    }
    getCompany() {
        return this.company;
    }
    setCompany(company) {
        this.company = company;
    }
    getCategory() {
        return this.category;
    }
    setCategory(category) {
        this.category = category;
    }
}
