"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DataSet = void 0;
var DataSet = /** @class */ (function () {
    function DataSet() {
        this.resultSet = [];
        // This is to keep track of the record for which we are exploring further datasets
        // e.g. Company name, URL, category, etc
        this.activeRecordRow = null;
        this.title = null;
        this.dataTypeBasic = null;
        this.dataTypeFull = null;
        this.rowIdentifier = null;
        this.defaultSortColumn = null;
        this.defaultSortDirection = 'asc';
        this.isSearchable = false;
        this.searchableFields = [];
        this.multiSelects = [];
        this.rangeFilters = [];
        this.tableColumns = [];
        this.tableRows = [];
        this.searchClass = null;
        this.multiSelectClass = null;
        this.rangeSliderClass = null;
        this.childDataSets = [];
        this.domain = '';
    }
    DataSet.prototype.getTitle = function () {
        return this.title;
    };
    DataSet.prototype.setTitle = function (title) {
        this.title = title;
    };
    DataSet.prototype.getDataTypeBasic = function () {
        return this.dataTypeBasic;
    };
    DataSet.prototype.setDataTypeBasic = function (dataTypeBasic) {
        this.dataTypeBasic = dataTypeBasic;
    };
    DataSet.prototype.getDataTypeFull = function () {
        return this.dataTypeFull;
    };
    DataSet.prototype.setDataTypeFull = function (dataTypeFull) {
        this.dataTypeFull = dataTypeFull;
    };
    DataSet.prototype.getRowIdentifier = function () {
        return this.rowIdentifier;
    };
    DataSet.prototype.setRowIdentifier = function (rowIdentifier) {
        this.rowIdentifier = rowIdentifier;
    };
    DataSet.prototype.getDefaultSortColumn = function () {
        return this.defaultSortColumn;
    };
    DataSet.prototype.setDefaultSortColumn = function (defaultSortColumn) {
        this.defaultSortColumn = defaultSortColumn;
    };
    DataSet.prototype.getDefaultSortDirection = function () {
        return this.defaultSortDirection;
    };
    DataSet.prototype.setDefaultSortDirection = function (defaultSortDirection) {
        this.defaultSortDirection = defaultSortDirection;
    };
    DataSet.prototype.getIsSearchable = function () {
        return this.isSearchable;
    };
    DataSet.prototype.setIsSearchable = function (isSearchable) {
        this.isSearchable = isSearchable;
    };
    DataSet.prototype.getSearchableFields = function () {
        return this.searchableFields;
    };
    DataSet.prototype.addSearchableField = function (searchableField) {
        this.searchableFields.push(searchableField);
    };
    DataSet.prototype.setSearchableFields = function (searchableFields) {
        this.searchableFields = searchableFields;
    };
    DataSet.prototype.getRangeFilters = function () {
        return this.rangeFilters;
    };
    DataSet.prototype.addRangeFilter = function (rangeFilter) {
        this.rangeFilters.push(rangeFilter);
    };
    DataSet.prototype.setRangeFilters = function (rangeFilters) {
        this.rangeFilters = rangeFilters;
    };
    DataSet.prototype.getMultiSelects = function () {
        return this.multiSelects;
    };
    DataSet.prototype.addMultiSelect = function (multiSelect) {
        this.multiSelects.push(multiSelect);
    };
    DataSet.prototype.setMultiSelects = function (multiSelects) {
        this.multiSelects = multiSelects;
    };
    DataSet.prototype.getTableColumns = function () {
        return this.tableColumns;
    };
    DataSet.prototype.addTableColumn = function (tableColumn) {
        this.tableColumns.push(tableColumn);
    };
    DataSet.prototype.setTableColumns = function (tableColumns) {
        this.tableColumns = tableColumns;
    };
    DataSet.prototype.getTableRows = function () {
        return this.tableRows.map(function (a) { return (__assign({}, a)); });
    };
    DataSet.prototype.addTableRow = function (row) {
        this.tableRows.push(row);
    };
    DataSet.prototype.setTableRows = function (rows) {
        this.tableRows = rows;
    };
    DataSet.prototype.getSearchClass = function () {
        return this.searchClass;
    };
    DataSet.prototype.setSearchClass = function (searchClass) {
        this.searchClass = searchClass;
    };
    DataSet.prototype.getMultiSelectClass = function () {
        return this.multiSelectClass;
    };
    DataSet.prototype.setMultiSelectClass = function (multiSelectClass) {
        this.multiSelectClass = multiSelectClass;
    };
    DataSet.prototype.getRangeSliderClass = function () {
        return this.rangeSliderClass;
    };
    DataSet.prototype.setRangeSliderClass = function (rangeSliderClass) {
        this.rangeSliderClass = rangeSliderClass;
    };
    DataSet.prototype.getResultSet = function () {
        return this.resultSet.map(function (a) { return (__assign({}, a)); });
    };
    DataSet.prototype.setResultSet = function (resultSet) {
        this.resultSet = resultSet;
    };
    DataSet.prototype.getChildDataSets = function () {
        return this.childDataSets;
    };
    DataSet.prototype.addChildDataSet = function (childDataSet) {
        this.childDataSets.push(childDataSet);
    };
    DataSet.prototype.setChildDataSets = function (childDataSets) {
        this.childDataSets = childDataSets;
    };
    DataSet.prototype.getActiveRecordRow = function () {
        return this.activeRecordRow;
    };
    DataSet.prototype.setActiveRecordRow = function (id) {
        var _this = this;
        this.activeRecordRow = this.getResultSet().filter(function (v) { return v[_this.getRowIdentifier()] === id; })[0];
    };
    DataSet.prototype.getDomain = function () {
        return this.domain;
    };
    DataSet.prototype.setDomain = function (domain) {
        this.domain = domain;
    };
    DataSet.prototype.getMultiSelectOptions = function (field) {
        // @ts-ignore
        var values = this.getTableRows().map(function (v) { return v[field]; });
        // @ts-ignore
        var unique = values.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        });
        // @ts-ignore
        return unique
            .filter(function (v) { return v.trim() !== ''; })
            .map(function (v) { return { name: v }; })
            .sort(function (a, b) { return a.name.localeCompare(b.name); });
    };
    DataSet.prototype.abbreviateNumber = function (number) {
        var symbol = ["", "k", "m", "b", "t"];
        var tier = Math.log10(Math.abs(number)) / 3 | 0;
        if (tier === 0)
            return number;
        var suffix = symbol[tier];
        var scale = Math.pow(10, tier * 3);
        var scaled = number / scale;
        return Number(scaled.toFixed(1)) + suffix;
    };
    DataSet.prototype.truncateScore = function (score) {
        return Math.round(score * 100);
    };
    return DataSet;
}());
exports.DataSet = DataSet;
