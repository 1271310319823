<template>

    <div class="container">

        <div class="row" ref="url-table-header">

            <div class="col-sm-12 col-md-9 section-header">
                <h2>Lists <font-awesome-icon v-if="fetching" :icon="['fas', 'spinner']" spin/></h2>
            </div>

            <div class="col-sm-12">
                <input
                    type="text"
                    id="list-search-text"
                    v-model="listsSearchText"
                    class="form-control"
                    :placeholder="'Search Lists ...'" />
            </div>
        </div>

        <div class="table-responsive results-table-wrapper">

            <table id="list-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '60%'}"
                        @click="sortColumn('name')"
                        class="clickable"
                        :class="{ active: currentSort === 'name' }">Name</th>
                    <th :style="{ width: '20%'}">Type</th>
                    <th :style="{ width: '20%'}"
                        @click="sortColumn('created_at')"
                        class="clickable"
                        :class="{ active: currentSort === 'created_at' }">Created</th>
                </tr>
                </thead>
                <tbody id="list-tbody">
                <tr v-for="(list, i) in lists" v-bind:key="i">
                    <td
                        @click="showList(list)"
                        class="expand-item"
                        :class="{
                            disabled: viewedListId && viewedListId === list.id
                        }"
                    >{{ list.name }}</td>
                    <td>{{ list.type.charAt(0).toUpperCase() + list.type.slice(1) }}</td>
                    <td>{{ new Date(parseInt(list.created_at) * 1000).toLocaleDateString("en-US") + ' ' + new Date(parseInt(list.created_at) * 1000).toLocaleTimeString("en-US") }}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>

</template>

<script>

    import '../../../assets/local.scss'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faSpinner);

    export default {
        name: 'ListsViewAll',
        props: {},
        components: {FontAwesomeIcon},
        emits: ["viewList", "expiredAuthToken", "fetchResults"],

        async mounted() {
            this.fetchCompanyLists();
        },

        data: () => ({
            fetching: true,
            allLists: [],
            companyLists: [],
            domainLists: [],
            listsSearchText: '',
            currentSortDir: 'desc',
            currentSort: 'created_at',
            viewedListId: null,
            viewedListName: null,
            creatingList: false
        }),

        computed: {

            /*
            This computed function is called by Vue anytime any of its dependent values are updated. It will re-evaluate the
            set of lists to show to the user based on sorting, searching, or filtering of list name
            */

            lists: function() {
                return [...this.allLists].sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    let cs = this.currentSort;
                    if (!a[cs] || !b[cs]) {
                        return 1;
                    }
                    let first = isNaN(a[cs]) ? a[cs].toLowerCase() : parseInt(a[cs], 10);
                    let second = isNaN(b[cs]) ? b[cs].toLowerCase() : parseInt(b[cs], 10);

                    if(first < second) return -1 * modifier;
                    if(first > second) return 1 * modifier;
                    return 0;
                }).filter(list => {
                    return this.listsSearchText
                    ? list.name && list.name.toLowerCase().includes(this.listsSearchText.toLowerCase())
                    : true;
                })
            },
        },

        methods: {

            async fetchCompanyLists() {
                this.$emit("fetchResults", {}, 'company-lists', this.fetchDomainLists);
            },

            async fetchDomainLists(companyLists) {
                this.companyLists = companyLists.map(v => ({...v, type: 'company'}));
                this.$emit("fetchResults", {}, 'domain-lists', this.setAllLists);
            },

            setAllLists(domainLists) {
                this.fetching = false;
                this.domainLists = domainLists.map(v => ({...v, type: 'domain'}));
                this.allLists = this.companyLists.concat(this.domainLists);
            },

            sortColumn:function(newSortValue) {
                if(newSortValue === this.currentSort) {
                    // If user clicks on sortable column that is already highlighted, reverse sort order
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                } else {
                    this.currentSortDir = 'asc';
                }
                this.currentSort = newSortValue;
            },

            showList:function(list) {
                this.$emit("showList", {type: list.type, name: list.name, id: list.id});
                this.viewedListName = list.name;
                this.viewedListId = list.id;
            },
        }
    }

</script>
