"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DomainCompanies = void 0;
var DataSet_1 = require("../DataSet");
var RangeFilter_1 = require("../RangeFilter");
var TableColumn_1 = require("../TableColumn");
var MultiSelect_1 = require("../MultiSelect");
var DomainCompanies = /** @class */ (function (_super) {
    __extends(DomainCompanies, _super);
    function DomainCompanies(resultSet, domain) {
        var _this = _super.call(this) || this;
        /**
         * Expected format for result set
         * {"companies": [
         *  {
         *    "visits": <int>,
         *    "name": <string>,
         *    "industry": <string>,
         *    "industrycode": <string>,
         *    "naicsindustry": <string>,
         *    "naicscode": <string>,
         *    "employees": <string> [multiple formats: 1 to 10, 100+],
         *    "revenue": <string> [multiple formats: $1 to $1,000,000, $1,000,000+],
         *    "website": <string>,
         *    "street": <string>,
         *    "city": <string>,
         *    "postal": <string>,
         *    "country": <string>,
         *    "phone": <string>,
         *    "facebook": <string>,
         *    "twitter": <string>,
         *    "linkedin": <string>
         *  }
         *]
         */
        _this.companies = [];
        _this.setResultSet(_this.processCompanies(resultSet));
        _this.setDomain(domain);
        _this.initialize();
        return _this;
    }
    DomainCompanies.prototype.initialize = function () {
        this.setDataTypeBasic('companies');
        this.setDataTypeFull('domain_companies');
        this.setDefaultSortColumn('visits');
        this.setDefaultSortDirection('desc');
        this.setCompanies(this.getResultSet());
        this.setTitle('Companies for ' + this.getDomain());
        this.setRowIdentifier('id');
        this.setIsSearchable(true);
        this.addSearchableField('name');
        this.createRangeFilters();
        this.createTableColumns();
        this.setTableRows(this.companies);
        this.createMultiSelects();
        this.setSearchClass('col-md-3');
        this.setMultiSelectClass('col-md-3');
        this.setRangeSliderClass('col-md-3');
    };
    DomainCompanies.prototype.createRangeFilters = function () {
        var _this = this;
        ['employees', 'revenue'].forEach(function (f) {
            var min = Math.min.apply(Math, _this.getCompanies().map(function (c) { return c[f]; }));
            var max = Math.max.apply(Math, _this.getCompanies().map(function (c) { return c[f]; }));
            if (min === max) {
                return;
            }
            var filter = new RangeFilter_1.RangeFilter();
            filter.setField(f);
            filter.setMax(max);
            filter.setMin(min);
            _this.addRangeFilter(filter);
        });
    };
    DomainCompanies.prototype.createMultiSelects = function () {
        var multiSelect = new MultiSelect_1.MultiSelect();
        multiSelect.setOptions(this.getMultiSelectOptions('industry'));
        multiSelect.setField('industry');
        this.addMultiSelect(multiSelect);
    };
    DomainCompanies.prototype.createTableColumns = function () {
        var tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setRowValueIsCheckable(true);
        tableColumn.setCheckedValueToAdd('name');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('Details');
        tableColumn.setRowValueClickSection('companyDetails');
        tableColumn.setRowValueClickIcon('info-circle');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('URLs');
        tableColumn.setRowValueClickSection('domainCompanyUrls');
        tableColumn.setRowValueClickIcon('bars');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('name');
        tableColumn.setRowValue('name');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('employees');
        tableColumn.setRowValue('employees');
        tableColumn.setRowValueDisplayFunc(this.abbreviateNumber);
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('industry');
        tableColumn.setRowValue('industry');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('revenue');
        tableColumn.setRowValue('revenue');
        tableColumn.setRowValueDisplayFunc(this.abbreviateNumber);
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn_1.TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('visits');
        tableColumn.setRowValue('visits');
        this.addTableColumn(tableColumn);
    };
    DomainCompanies.prototype.processCompanies = function (companies) {
        return companies.map(function (c) {
            return {
                name: c.company_name,
                domains: c.domains,
                id: c.company_id,
                visits: c.visits,
                employees_lower: c.employees_lower,
                employees_upper: c.employees_upper,
                employees: c.employees_upper || c.employees_lower,
                revenue_lower: c.revenue_lower,
                revenue_upper: c.revenue_upper,
                revenue: c.revenue_upper || c.revenue_lower,
                social_platforms: c.company_social_platforms,
                industry: c.company_sic_codes ? c.company_sic_codes.map(function (sic) {
                    return sic.name;
                }).join(', ') : '',
                sic_codes: c.company_sic_codes,
                naics_codes: c.company_naics_codes
            };
        });
    };
    DomainCompanies.prototype.getCompanies = function () {
        // @ts-ignore
        return this.companies.map(function (a) { return (__assign({}, a)); });
    };
    DomainCompanies.prototype.setCompanies = function (companies) {
        this.companies = companies;
    };
    return DomainCompanies;
}(DataSet_1.DataSet));
exports.DomainCompanies = DomainCompanies;
