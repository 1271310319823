<template>

    <div class="container">

        <div class="row" ref="url-table-header">

            <div class="col-sm-12 col-md-9 section-header">
                <h2>{{listName}} IP Ranges <font-awesome-icon v-if="fetching" :icon="['fas', 'spinner']" spin/></h2>
            </div>
            <div class="col-sm-12 col-md-3 section-header">
                <button
                        id="csv-export-companies"
                        class="btn btn-funnel-fuel float-end"
                        type="button"
                        @click="csvExport(listRanges, 'ip_ranges')">
                    <span>Download CSV</span>
                </button>
            </div>

        </div>

        <!-- Instead of explicitly returning a list type, we simply examine the data to determine it -->
        <div class="table-responsive results-table-wrapper">

            <table id="company-list-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '35%'}">Company</th>
                    <th :style="{ width: '35%'}">Domain</th>
                    <th :style="{ width: '30%'}">IP Range</th>
                </tr>
                </thead>
                <tbody id="company-list-tbody">
                <tr v-for="(company, i) in listRanges" v-bind:key="i">
                    <td>{{company.company}}</td>
                    <td>{{company.domain}}</td>
                    <td>{{company.ip_range}}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>

</template>

<script>

    import '../../../../assets/local.scss'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faSpinner);

    export default {
        name: 'CompanyListIpRanges',
        props: {
            listId: String,
            listName: String,
            listMatchRateRange: Array,
        },
        components: {FontAwesomeIcon},
        emits: ["expiredAuthToken", "fetchResults", "csvExport"],

        async mounted() {
            this.fetchListIpRanges();
        },

        data: () => ({
            fetching: true,
            listRanges: [],
            listType: null
        }),

        methods: {

            async fetchListIpRanges() {
                this.$emit(
                    "fetchResults",
                    {
                        'list-id': this.listId,
                        'match-rate-range': this.listMatchRateRange[0] + '_' + this.listMatchRateRange[1]
                    },
                    'company-list-ip-ranges',
                    this.setListRanges
                );
            },

            setListRanges(ranges) {
              this.fetching = false;
              this.listRanges = ranges;
            },

            csvExport(data, type) {
                this.$emit('csvExport', this.listName, type, data)
            }

        }
    }

</script>
