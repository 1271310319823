"use strict";
exports.__esModule = true;
exports.TableColumn = void 0;
var TableColumn = /** @class */ (function () {
    function TableColumn() {
        this.headerIsSortable = false;
        this.headerValue = null;
        this.rowValue = null;
        this.rowValueDisplayFunc = null;
        this.rowValueIsClickable = false;
        this.rowValueIsExternalClickable = false;
        this.rowValueExternalClickableLinkRowValue = null;
        this.rowValueClickIcon = null;
        this.rowValueClickSection = null;
        this.rowValueIsCheckable = false;
        this.checkedValueToAdd = null;
        this.rowValueRequiresAdmin = false;
        this.rowValueToolTip = null;
    }
    TableColumn.prototype.getHeaderIsSortable = function () {
        return this.headerIsSortable;
    };
    TableColumn.prototype.setHeaderIsSortable = function (headerIsSortable) {
        this.headerIsSortable = headerIsSortable;
    };
    TableColumn.prototype.getHeaderValue = function () {
        return this.headerValue;
    };
    TableColumn.prototype.setHeaderValue = function (headerValue) {
        this.headerValue = headerValue;
    };
    TableColumn.prototype.getRowValue = function () {
        return this.rowValue;
    };
    TableColumn.prototype.setRowValue = function (rowValue) {
        this.rowValue = rowValue;
    };
    TableColumn.prototype.getRowValueDisplayFunc = function () {
        return this.rowValueDisplayFunc;
    };
    TableColumn.prototype.setRowValueDisplayFunc = function (rowValueDisplayFunc) {
        this.rowValueDisplayFunc = rowValueDisplayFunc;
    };
    TableColumn.prototype.callRowValueDisplayFunc = function (value) {
        return this.rowValueDisplayFunc(value);
    };
    TableColumn.prototype.getRowValueIsClickable = function () {
        return this.rowValueIsClickable;
    };
    TableColumn.prototype.setRowValueIsClickable = function (rowValueIsClickable) {
        this.rowValueIsClickable = rowValueIsClickable;
    };
    TableColumn.prototype.getRowValueIsExternalClickable = function () {
        return this.rowValueIsExternalClickable;
    };
    TableColumn.prototype.setRowValueIsExternalClickable = function (rowValueIsExternalClickable) {
        this.rowValueIsExternalClickable = rowValueIsExternalClickable;
    };
    TableColumn.prototype.getRowValueExternalClickableLinkRowValue = function () {
        return this.rowValueExternalClickableLinkRowValue;
    };
    TableColumn.prototype.setRowValueExternalClickableLinkRowValue = function (rowValueExternalClickableLinkRowValue) {
        this.rowValueExternalClickableLinkRowValue = rowValueExternalClickableLinkRowValue;
    };
    TableColumn.prototype.getRowValueClickIcon = function () {
        return this.rowValueClickIcon;
    };
    TableColumn.prototype.setRowValueClickIcon = function (rowValueClickIcon) {
        this.rowValueClickIcon = rowValueClickIcon;
    };
    TableColumn.prototype.getRowValueClickSection = function () {
        return this.rowValueClickSection;
    };
    TableColumn.prototype.setRowValueClickSection = function (rowValueClickSection) {
        this.rowValueClickSection = rowValueClickSection;
    };
    TableColumn.prototype.getRowValueIsCheckable = function () {
        return this.rowValueIsCheckable;
    };
    TableColumn.prototype.setRowValueIsCheckable = function (rowValueIsCheckable) {
        this.rowValueIsCheckable = rowValueIsCheckable;
    };
    TableColumn.prototype.getCheckedValueToAdd = function () {
        return this.checkedValueToAdd;
    };
    TableColumn.prototype.setCheckedValueToAdd = function (checkedValueToAdd) {
        this.checkedValueToAdd = checkedValueToAdd;
    };
    TableColumn.prototype.getRowValueRequiresAdmin = function () {
        return this.rowValueRequiresAdmin;
    };
    TableColumn.prototype.setRowValueRequiresAdmin = function (rowValueRequiresAdmin) {
        this.rowValueRequiresAdmin = rowValueRequiresAdmin;
    };
    TableColumn.prototype.getRowValueTooltip = function () {
        return this.rowValueToolTip;
    };
    TableColumn.prototype.setRowValueTooltip = function (rowValueToolTip) {
        this.rowValueToolTip = rowValueToolTip;
    };
    return TableColumn;
}());
exports.TableColumn = TableColumn;
