<template>

    <div class="container">

        <!-- tmp spacer until css is finalised -->
        <div style="height:30px"></div>

        <div class = "mb-6">
            <label for="listType">List Type</label>
            <select
                id="listType"
                v-model="listType"
                class="form-control">
            <option value="companies">Companies</option>
            <option value="domains">Domains</option>
            </select>
            <label for="name">Name</label>
            <input type="text" id="name" class="form-control" v-model="listName" />
            <label for="items">Items</label>
            <textarea class="form-control" id="items" v-model="listItems"></textarea>
        <span id="id-result">{{getCreatedId()}}</span>
        </div>

        <div class="row" id="cl-create-button">
            <div class="col-sm-3">
                <button
                    id="create-cl-data"
                    class="btn btn-funnel-fuel"
                    type="button"
                    :disabled="CLCreateIsDisabled"
                    @click="createCL">
                    <span
                        v-if="sendingCLData"
                        class="spinner-border spinner-border-sm fetching-results-spinner"
                        role="status"
                        aria-hidden="true">
                    </span>
                    <span>Create List</span>
                </button>
            </div>
            <div class="col-sm-3">
                <button
                    id="cancel-create"
                    class="btn btn-funnel-fuel"
                    type="button"
                    @click="cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

    </div>

</template>

<script>

    import '../../../assets/local.scss'

    export default {
        name: 'ListCreate',
        components: {},
        props: {},
        emits: ["created", "cancelled", "expiredAuthToken"],

        data: () => ({
            sendingCLData: false,
            listItems: '',
            listName: '',
            listType: '',
            listCreatedId: '',
            listHits: [],
            listMisses: []
        }),

        computed: {
            CLCreateIsDisabled: function() {
                return !this.listType || !this.listName || !this.listItems || this.sendingCLData;
            }
        },

        methods: {

            getCreatedId: function() {
                let prefix = '';
                if (this.listCreatedId) {
                    prefix = 'Created list id: ';
                }
                return prefix + this.listCreatedId;
            },

            async createCL() {

                // Set flag to true so we can disable additional button clicks while fetching
                this.sendingCLData = true;

                let post_body = {'type': this.listType, 'name': this.listName, 'items': this.listItems};

                let CLCreateData = await this.sendCLList(post_body);

                // Set visible
                let that = this;
                await (function() {
                    that.listCreatedId = CLCreateData.id;
                    that.listHits = CLCreateData.hits;
                    that.listMisses = CLCreateData.misses;
                })();

                // Set flag to false so we can re-enable button
                this.sendingCLData = false;

                this.$emit("created", true);
            },

            async sendCLList(post_body) {

                let url = '';

                let path = '';
                if (post_body.type == 'companies') {
                    path = 'company-match';
                } else if (post_body.type == 'domains') {
                    path = 'domain-list';
                }

                if (window.location.hostname.indexOf('staging') !== -1) {
                    url = new URL("https://s5q7peapri.execute-api.eu-west-2.amazonaws.com/staging/" + path)
                } else if (window.location.hostname.indexOf('localhost') !== -1) {
                    url = new URL("http://127.0.0.1:8000/" + path);
                } else {
                    url = new URL("https://xrmq3ziod7.execute-api.eu-west-2.amazonaws.com/dev/" + path);
                }

                let start = performance.now();

                const results = await fetch(url.toString(), {
                    method: "POST",
                    body: JSON.stringify(post_body),
                    headers: new Headers({
                        'Authorization': this.$store.state.authToken
                    })
                }).then(async (response) => {
                    if (parseInt(response.status) === 401) {
                    /*
                    The API Gateway will return a 401 unauthorized if the JWT Token has expired. If this happens, we need to
                    emit this event to the parent so we can log out the user and show the login screen again.
                    */
                    this.$emit("expiredAuthToken", true);
                        return [];
                    }
                    if (response.status >= 400 && response.status < 600) {
                        let text = await response.text();
                        this.$store.dispatch('sendLogs', {'ErrorMessage': text, 'URL': url, 'Application': 'abm-client'});
                        return [];
                    } else {
                        return response.json();
                    }
                }).catch((error) => {
                    this.$store.dispatch('sendLogs', {'ErrorMessage': error.message, 'URL': url, 'Application': 'abm-client'});
                    return [];
                });
                let end = performance.now();
                let totalFetchTime = end - start;
                this.$store.dispatch('sendLogs', {'FetchUrl': url.toString(), 'FetchTiming': totalFetchTime});

                return results;
            },

            cancel() {
                this.$emit("cancelled", true);
            }
        }

    }

</script>
