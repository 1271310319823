import { DataSet } from "../DataSet";
import { RangeFilter } from "../RangeFilter";
import { TableColumn } from "../TableColumn";
import { MultiSelect } from "../MultiSelect";
export class DomainCategories extends DataSet {
    constructor(resultSet, domain) {
        super();
        /**
         * Expected format for result set
         * [{"key": "<string>", "parent": "<string>", "child": "<string>", "type": "<string>", "urls": "<string>"}]
         */
        this.categories = [];
        this.setResultSet(resultSet);
        this.setDomain(domain);
        this.initialize();
    }
    initialize() {
        this.setDataTypeBasic('categories');
        this.setDataTypeFull('domain_categories');
        this.setDefaultSortColumn('urls');
        this.setDefaultSortDirection('desc');
        this.setCategories(this.getResultSet());
        this.setTitle('Categories for ' + this.getDomain());
        this.setRowIdentifier('key');
        this.setIsSearchable(true);
        this.addSearchableField('parent');
        this.addSearchableField('child');
        this.createRangeFilters();
        this.createTableColumns();
        this.setTableRows(this.categories);
        this.createMultiSelects();
        this.setSearchClass('col-md-3');
        this.setRangeSliderClass('col-md-3');
        this.setMultiSelectClass('col-md-3');
    }
    createRangeFilters() {
        let min = Math.min.apply(Math, this.getCategories().map(c => c.urls));
        let max = Math.max.apply(Math, this.getCategories().map(c => c.urls));
        if (min === max) {
            return;
        }
        let filter = new RangeFilter();
        filter.setField('urls');
        filter.setMax(max);
        filter.setMin(min);
        this.addRangeFilter(filter);
    }
    createMultiSelects() {
        let multiSelect = new MultiSelect();
        multiSelect.setOptions(this.getMultiSelectOptions('parent'));
        multiSelect.setField('parent');
        this.addMultiSelect(multiSelect);
        multiSelect = new MultiSelect();
        multiSelect.setOptions(this.getMultiSelectOptions('type'));
        multiSelect.setField('type');
        this.addMultiSelect(multiSelect);
    }
    createTableColumns() {
        let tableColumn = new TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('URLs');
        tableColumn.setRowValueClickSection('domainCategoryUrls');
        tableColumn.setRowValueClickIcon('bars');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('Companies');
        tableColumn.setRowValueClickSection('domainCategoryCompanies');
        tableColumn.setRowValueClickIcon('building');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderValue('Parent');
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setRowValue('parent');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderValue('Child');
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setRowValue('child');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderValue('Type');
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setRowValue('type');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('URL Count');
        tableColumn.setRowValue('urls');
        this.addTableColumn(tableColumn);
    }
    getCategories() {
        return this.categories.map(a => (Object.assign({}, a)));
    }
    setCategories(categories) {
        this.categories = categories;
    }
}
