import { DataSet } from "../DataSet";
import { RangeFilter } from "../RangeFilter";
import { TableColumn } from "../TableColumn";
import { MultiSelect } from "../MultiSelect";
export class DomainCategoryCompanies extends DataSet {
    constructor(resultSet, domain, category) {
        super();
        /**
         * Expected format for result set
         * {"companies": [
         *  {
         *    "visits": <int>,
         *    "name": <string>,
         *    "industry": <string>,
         *    "industrycode": <string>,
         *    "naicsindustry": <string>,
         *    "naicscode": <string>,
         *    "employees": <string> [multiple formats: 1 to 10, 100+],
         *    "revenue": <string> [multiple formats: $1 to $1,000,000, $1,000,000+],
         *    "website": <string>,
         *    "street": <string>,
         *    "city": <string>,
         *    "postal": <string>,
         *    "country": <string>,
         *    "phone": <string>,
         *    "facebook": <string>,
         *    "twitter": <string>,
         *    "linkedin": <string>
         *  }
         *]
         */
        this.companies = [];
        this.domain = '';
        this.category = '';
        this.setResultSet(resultSet);
        this.setDomain(domain);
        this.setCategory(category);
        this.initialize();
    }
    initialize() {
        this.setDataTypeBasic('companies');
        this.setDataTypeFull('domain_category_companies');
        this.setDefaultSortColumn('visits');
        this.setDefaultSortDirection('desc');
        this.setCompanies(this.processEmployeeAndRevenueValues(this.getResultSet()).filter(c => c.name !== ''));
        this.setTitle('Companies for ' + this.getDomain() + ' - ' + this.getCategory());
        this.setRowIdentifier('name');
        this.setIsSearchable(true);
        this.addSearchableField('name');
        this.createRangeFilters();
        this.createTableColumns();
        this.setTableRows(this.getCompanies());
        this.createMultiSelects();
        this.setSearchClass('col-md-3');
        this.setMultiSelectClass('col-md-3');
        this.setRangeSliderClass('col-md-3');
    }
    createRangeFilters() {
        ['employees', 'revenue'].forEach(f => {
            let min = Math.min.apply(Math, this.getCompanies().map(c => c[f]));
            let max = Math.max.apply(Math, this.getCompanies().map(c => c[f]));
            if (min === max) {
                return;
            }
            let filter = new RangeFilter();
            filter.setField(f);
            filter.setMax(max);
            filter.setMin(min);
            this.addRangeFilter(filter);
        });
    }
    createMultiSelects() {
        let multiSelect = new MultiSelect();
        multiSelect.setOptions(this.getMultiSelectOptions('industry'));
        multiSelect.setField('industry');
        this.addMultiSelect(multiSelect);
    }
    createTableColumns() {
        let tableColumn = new TableColumn();
        tableColumn.setRowValueIsCheckable(true);
        tableColumn.setCheckedValueToAdd('name');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('Details');
        tableColumn.setRowValueClickSection('companyDetails');
        tableColumn.setRowValueClickIcon('info-circle');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setRowValueIsClickable(true);
        tableColumn.setRowValueTooltip('URLs');
        tableColumn.setRowValueClickSection('domainCategoryCompanyUrls');
        tableColumn.setRowValueClickIcon('bars');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('name');
        tableColumn.setRowValue('name');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('employees');
        tableColumn.setRowValue('employees');
        tableColumn.setRowValueDisplayFunc(this.abbreviateNumber);
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('industry');
        tableColumn.setRowValue('industry');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('revenue');
        tableColumn.setRowValue('revenue');
        tableColumn.setRowValueDisplayFunc(this.abbreviateNumber);
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('visits');
        tableColumn.setRowValue('visits');
        this.addTableColumn(tableColumn);
    }
    processEmployeeAndRevenueValues(companies) {
        return companies.map(c => {
            if (c.employees !== '') {
                if (c.employees.includes('to')) {
                    // 100 to 250
                    c.employees = c.employees.split('to').map(v => v.trim())[1];
                }
                // 100+
                c.employees = parseInt(c.employees.replace(/[+,]/g, ''));
            }
            else {
                c.employees = 0;
            }
            if (c.revenue !== '') {
                if (c.revenue.includes('to')) {
                    // $1,000,000 to $1,500,000
                    c.revenue = c.revenue.split('to').map(v => v.trim())[1];
                }
                // $1,000,000+
                c.revenue = parseInt(c.revenue.replace(/[$+,]/g, ''));
            }
            else {
                c.revenue = 0;
            }
            return c;
        });
    }
    getCompanies() {
        return this.companies.map(a => (Object.assign({}, a)));
    }
    setCompanies(companies) {
        this.companies = companies;
    }
    getDomain() {
        return this.domain;
    }
    setDomain(domain) {
        this.domain = domain;
    }
    getCategory() {
        return this.category;
    }
    setCategory(category) {
        this.category = category;
    }
}
