export default {

  createCsvFileName: function(originalName, type, dateRange=null) {
    let fileName = originalName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    fileName = fileName.replace(/_+/g, '_');
    fileName = fileName.replace(/^_/, '');
    fileName += "_" + type;
    if (dateRange) {
      fileName += "_" + dateRange.startDate.replace(/-/gi,'_') + '_' + dateRange.endDate.replace(/-/gi,'_');
    }
    fileName += '.csv';

    return fileName;
  },

  cleanDataForCsv: function(data) {
    return Object.values(data).map(v => {
      if (Array.isArray(v)) {
        return null;
      }
      if (v === undefined) {
        v = '';
      }
      if (Number.isInteger(v)) {
        v = v.toString();
      }
      v = v.replace('"', '\\"');
      if (v.includes(',')) {
        v = '"' + v + '"';
      }
      return v;
    }).filter(v => v).join(",");
  },

  downloadCsv: function(encodedData, fileName) {
    // Simulate click for download
    let link = document.createElement("a");
    link.href = encodedData;
    link.download = fileName;
    link.click();
  }
}
