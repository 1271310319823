"use strict";
exports.__esModule = true;
exports.RangeFilter = void 0;
var RangeFilter = /** @class */ (function () {
    function RangeFilter() {
        this.field = null;
        this.min = null;
        this.max = null;
    }
    RangeFilter.prototype.getField = function () {
        return this.field;
    };
    RangeFilter.prototype.setField = function (field) {
        this.field = field;
    };
    RangeFilter.prototype.getMin = function () {
        return this.min;
    };
    RangeFilter.prototype.setMin = function (min) {
        this.min = min;
    };
    RangeFilter.prototype.getMax = function () {
        return this.max;
    };
    RangeFilter.prototype.setMax = function (max) {
        this.max = max;
    };
    RangeFilter.prototype.getMinMax = function () {
        return [this.min, this.max];
    };
    return RangeFilter;
}());
exports.RangeFilter = RangeFilter;
