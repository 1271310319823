<template>

  <div class="container" v-if="resultsExist === false">

    <div class="row">

      <div class="col-sm-12 section-header">
        <h2>{{message['title']}}</h2>
      </div>

    </div>

    <div>

      <div class="col-sm-12 section-header-warning">
        <h2>{{message['body']}}</h2>
      </div>

    </div>

  </div>

  <div class="container" v-if="resultsExist">

    <div class="row">

      <div class="col-sm-12 col-md-9 section-header">
        <h2>{{dataSet.getTitle()}}</h2>
      </div>

      <div class="col-sm-12 col-md-3 section-header">
        <button
            :id="componentName + 'CsvExport'"
            class="btn btn-funnel-fuel float-end csv-export"
            type="button"
            @click="csvExport(rows)">
        <span>Download CSV</span>
        </button>
      </div>

      <div v-if="dataSet.getIsSearchable()" class="col-sm-12" :class="dataSet.getSearchClass()">
        <input
            type="text"
            :id="componentName + 'Search'"
            v-model="searchText"
            class="form-control"
            :placeholder="'Search ' + capitalizeFirstLetter(dataSet.getDataTypeBasic()) + ' ...'" />
      </div>

      <div
          v-for="(multiSelect, i) in dataSet.getMultiSelects()"
          v-bind:key="i"
          class="vuemultiselect col-sm-12"
          :class="dataSet.getMultiSelectClass()"
          :id="componentName + capitalizeFirstLetter(multiSelect.getField()) + 'MultiSelect'">
        <VueMultiselect
            v-model="multiSelects[i]"
            :options="multiSelect.getOptions()"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :placeholder="'Filter ' +  capitalizeFirstLetter(multiSelect.getField())"
            label="name"
            track-by="name">
        </VueMultiselect>
      </div>

      <div v-for="(filter, i) in dataSet.getRangeFilters()" v-bind:key="i" class="col-sm-12" :class="dataSet.getRangeSliderClass()">
        <div v-if="mins[i] >= 0 && maxes[i] >= 0 && ranges.length >= i+1" class="row slider-wrapper" :id="componentName + capitalizeFirstLetter(filter.getField()) + 'Slider'">
          <div class="col-sm-6 slider-label">
            {{capitalizeFirstLetter(filter.getField())}}
          </div>
          <div class="col-sm-6 slider-values">
            <span class="rangeValues">
                {{dataSet.abbreviateNumber(ranges[i][0]) + '-' + dataSet.abbreviateNumber(ranges[i][1])}}
            </span>
          </div>
        </div>
        <vue-slider
            v-if="mins[i] >= 0 && maxes[i] >= 0 && ranges.length >= i+1"
            :min="mins[i]"
            :max="maxes[i]"
            v-bind="sliderOptions"
            v-model="ranges[i]" />
      </div>

    </div>

    <div class="table-responsive results-table-wrapper">

      <table class="table table-striped" :id="componentName + 'Table'">
        <thead>
          <tr>
            <!-- reminder that I can add things like this if necessary-->
            <!-- :style="{ width: i === 0 ? '80%' : '5%' }"-->
            <th v-for="(column,i) in dataSet.getTableColumns()"
                v-bind:key="i"
                scope="col"
                @click="column.getHeaderIsSortable() ? sortColumn(column.getRowValue().toLowerCase()) : null"
                :class="{ clickable: column.getHeaderIsSortable(), active: column.getRowValue() ? currentSortColumn === column.getRowValue().toLowerCase() : null}">
              {{ column.getHeaderValue() ? capitalizeFirstLetter(column.getHeaderValue()) : '' }}
              <input v-if="column.getRowValueIsCheckable()" type="checkbox" @click="toggleAllForDownload()" :checked="csvDownloadValues.length ? true : null">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,i) in rows" v-bind:key="i">
            <td v-for="(column,j) in dataSet.getTableColumns()"
                v-bind:key="j"
                :class="{clickable: column.getRowValueIsClickable(), disabled: activeCells.includes(row[dataSet.getRowIdentifier()] + 'x' + j) }"
                @click="column.getRowValueIsClickable() && !activeCells.includes(row[dataSet.getRowIdentifier()] + 'x' + j) ? showSection(column.getRowValueClickSection(), row[dataSet.getRowIdentifier()], j) : null">
              <span v-if="busyCell === row[dataSet.getRowIdentifier()] + 'x' + j" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <font-awesome-icon v-if="column.getRowValueIsClickable() && (busyCell !== row[dataSet.getRowIdentifier()] + 'x' + j)" :icon="column.getRowValueClickIcon()" :title="column.getRowValueTooltip() ? column.getRowValueTooltip() : ''"/>
              <a v-if="column.getRowValueIsExternalClickable()" target="_blank" :href="row[column.getRowValueExternalClickableLinkRowValue()]">
                <font-awesome-icon :icon="column.getRowValueClickIcon()" :title="column.getRowValueTooltip() ? column.getRowValueTooltip() : ''"/>
              </a>
              <input v-if="column.getRowValueIsCheckable()" type="checkbox" v-model="csvDownloadValues" :value="row[column.getCheckedValueToAdd()]">
              {{ column.getRowValue() ? (column.getRowValueDisplayFunc() ? column.callRowValueDisplayFunc(row[column.getRowValue()]) : row[column.getRowValue()]) : '' }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
  import VueMultiselect from 'vue-multiselect'
  import * as VueSlider from 'vue-slider-component'
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faCode, faBars, faInfoCircle, faChartBar, faLayerGroup, faExternalLinkAlt, faBuilding } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  library.add(faCode, faBars, faInfoCircle, faChartBar, faLayerGroup, faExternalLinkAlt, faBuilding);

  export default {

    name: "SearchSortFilterTable",

    components: {
      VueMultiselect,
      VueSlider,
      FontAwesomeIcon,
    },

    // Can be either of type DataSet or plain object with message data, if we have no results
    props: ['dataSet'],

    emits: ["showSection", "csvExport"],

    data: () => ({
      currentSortColumn: '',
      currentSortDirection: '',
      searchText: '',
      multiSelects: [],
      sliderOptions: {
        processStyle: {backgroundColor: '#4d57c5'},
        dotStyle: {backgroundColor: 'white', borderColor: '#4d57c5'},
        tooltip: 'none'
      },
      csvDownloadValues: [],
      busyCell: '',
      activeCells: [],
      mins: [],
      maxes: [],
      ranges: [],
      componentName: null,
      resultsExist: null,
      message: null
    }),

    mounted() {

      if (Object.hasOwnProperty.call(this.dataSet, 'message')) {
        // We had no results
        this.message = {
          'title': this.dataSet['message']['title'],
          'body': this.dataSet['message']['body']
        };
        this.resultsExist = false;
        return;
      }

      this.resultsExist = true;
      let mins = this.dataSet.getRangeFilters().map(rf => rf.getMin());
      let maxes = this.dataSet.getRangeFilters().map(rf => rf.getMax());
      this.ranges = mins.map((m,i) => [m, maxes[i]]);
      this.mins = mins;
      this.maxes = maxes;
      this.currentSortColumn = this.dataSet.getDefaultSortColumn();
      this.currentSortDirection = this.dataSet.getDefaultSortDirection();
      this.toggleAllForDownload();
      this.componentName = this.dataSet.constructor.name;
    },

    errorCaptured(callback) {
        console.log('error captured');
        console.log(callback);
    },

    computed: {

      rows: function() {

        let filterResults = [...this.dataSet.getTableRows()].sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDirection === 'desc') modifier = -1;
          let field = this.currentSortColumn;
          if (!a[field] || !b[field]) {
            return 1;
          }
          let first = isNaN(a[field]) ? a[field].toLowerCase() : parseInt(a[field], 10);
          let second = isNaN(b[field]) ? b[field].toLowerCase() : parseInt(b[field], 10);

          if(first < second) return -1 * modifier;
          if(first > second) return modifier;
          return 0;
        });

        if (this.dataSet.getIsSearchable()) {
          let searchableFields = this.dataSet.getSearchableFields();
          filterResults = filterResults.filter(row => {
            if (this.searchText) {
              let found = false;
              searchableFields.forEach(sf => {
                if (row[sf] && row[sf].toLowerCase().includes(this.searchText.toLowerCase())) {
                  found = true;
                }
              });
              return found;
            }
            return true;
          })
        }

        if (this.dataSet.getMultiSelects().length) {
          this.dataSet.getMultiSelects().forEach((ms, index) => {
            filterResults = filterResults.filter(row => {
              if (this.multiSelects[index]) {
                let selections = this.multiSelects[index].map(v => v.name);
                return selections.length ? selections.includes(row[ms.getField()]) : true;
              }
              return true;
            })
          });
        }

        if (this.dataSet.getRangeFilters().length) {
          this.dataSet.getRangeFilters().forEach((rf, index) => {
            filterResults = filterResults.filter(row => {
              if (rf.getMinMax().length && this.ranges[index]) {
                return row[rf.getField()] >= this.ranges[index][0] && row[rf.getField()] <= this.ranges[index][1];
              }
              return true;
            })
          })
        }

        return filterResults;
      },
    },

    methods: {

      sortColumn: function(newSortColumn) {
        if(newSortColumn === this.currentSortColumn) {
          // If user clicks on sortable column that is already highlighted, reverse sort order
          this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
        } else {
          this.currentSortDirection = 'asc';
        }
        this.currentSortColumn = newSortColumn;
      },

      toggleAllForDownload: function() {
        if (this.csvDownloadValues.length) {
          this.csvDownloadValues = [];
        } else {
          this.csvDownloadValues = this.dataSet.getTableRows().map(v => v.name).filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        }
      },

      showSection(section, id, columnId) {
        if (this.dataSet.getActiveRecordRow() && this.dataSet.getActiveRecordRow()[this.dataSet.getRowIdentifier()] !== id) {
          this.activeCells = [];
          this.dataSet.setChildDataSets([]);
        }
        this.dataSet.setActiveRecordRow(id);
        this.busyCell = id + 'x' + columnId;
        this.activeCells.push(id + 'x' + columnId);
        this.dataSet.addChildDataSet(section);
        this.$emit("showSection", section, id, this);
      },

      finishedFetching() {
        this.busyCell = [];
      },

      capitalizeFirstLetter: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },

      csvExport(rows) {
        // This is to convert from Proxy to array of objects
        let rowsToExport = JSON.parse(JSON.stringify(rows));

        if (this.csvDownloadValues.length) {
            rowsToExport = rowsToExport.filter(r => this.csvDownloadValues.includes(r.name))
        }

        // Originally had the below. for removing duplicates?
        // this.companiesToIncludeInCsvDownload = this.companies.map(v => v.name).filter((value, index, self) => {
        //   return self.indexOf(value) === index;
        // });

        this.$emit("csvExport", rowsToExport, this.dataSet.getDataTypeFull())
      },

    }
  }
</script>

