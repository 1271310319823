<template>

  <div class="container">

      <!-- Selection options for data -->

    <div class="row">
      <div class="form-group col-sm-12 col-md-3">
        <label for="dataset">Dataset</label>
        <select
          id="dataset"
          v-model="dataset"
          class="form-control">
          <option value="urls">URLs</option>
          <option value="companies">Companies</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-3">
        <label for="website">Website</label>
        <select
          id="website"
          v-model="website"
          class="form-control">
          <option v-for="website in websiteOptions" v-bind:key="website" :value="website">{{website}}</option>
          <!-- TODO after choosing and fetching, if user switches this, before hitting fetch again, it changes the title below -->
        </select>
      </div>
        <div class="flex-column col-sm-12 col-md-6">
          <label>Date Range</label>
          <litepie-datepicker
            id="datepicker"
            use-range
            :shortcuts="true"
            :formatter="dateRangeFormatter"
            v-model="dateRange">
          </litepie-datepicker>
        </div>
    </div>

    <div class="row" id="show-results-button">
      <div class="form-group col-sm-12 col-md-4">
        <button
          id="fetch-data"
          class="btn btn-funnel-fuel"
          type="button"
          :disabled="fetchIsDisabled"
          @click="handleFetchClick(dataset)">
          <span
            v-if="fetchingInitialResults"
            class="spinner-border spinner-border-sm fetching-results-spinner"
            role="status"
            aria-hidden="true"> </span>
          <span>Fetch Results</span>
        </button>
      </div>
    </div>

    <div class="row" v-if="emptyInitialResultSet">

      <div class="col-sm-12 section-header-warning">

        <h2>{{emptyResultSetMessage}}</h2>

      </div>

    </div>

    <div v-if="viewDomainUrlsTable" class="row" ref="domain-urls-table-header">

      <SearchSortFilterTable :dataSet="domainUrlsDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainUrlsTable" />

    </div>

    <div v-if="viewDomainCompaniesTable" class="row" ref="domain-companies-table-header">

      <SearchSortFilterTable :dataSet="domainCompaniesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCompaniesTable" />

    </div>

    <div v-if="viewDomainCategoriesTable" class="row" ref="domain-categories-table-header">

      <SearchSortFilterTable :dataSet="domainCategoriesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCategoriesTable" />

    </div>

    <div v-if="viewDomainUrlCompaniesTable" class="row" ref="domain-url-companies-table-header">

      <SearchSortFilterTable :dataSet="domainUrlCompaniesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainUrlCompaniesTable" />

    </div>

    <div v-if="viewDomainCategoryUrlsTable" class="row" ref="domain-category-urls-table-header">

      <SearchSortFilterTable :dataSet="domainCategoryUrlsDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCategoryUrlsTable" />

    </div>

    <div v-if="viewDomainCategoryUrlCompaniesTable" class="row" ref="domain-category-url-companies-table-header">

      <SearchSortFilterTable :dataSet="domainCategoryUrlCompaniesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCategoryUrlCompaniesTable" />

    </div>

    <div v-if="viewDomainCategoryCompaniesTable" class="row" ref="domain-category-companies-table-header">

      <SearchSortFilterTable :dataSet="domainCategoryCompaniesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCategoryCompaniesTable" />

    </div>

    <div v-if="viewDomainCompanyUrlsTable" class="row" ref="domain-company-urls-table-header">

      <SearchSortFilterTable :dataSet="domainCompanyUrlsDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCompanyUrlsTable" />

    </div>

    <div v-if="viewDomainCompanyCategoriesTable" class="row" ref="domain-company-categories-table-header">

      <SearchSortFilterTable :dataSet="domainCompanyCategoriesDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCompanyCategoriesTable" />

    </div>

    <div v-if="viewDomainCompanyCategoryUrlsTable" class="row" ref="domain-company-category-urls-table-header">

      <SearchSortFilterTable :dataSet="domainCompanyCategoryUrlsDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCompanyCategoryUrlsTable" />

    </div>

    <div v-if="viewDomainCategoryCompanyUrlsTable" class="row" ref="domain-category-company-urls-table-header">

      <SearchSortFilterTable :dataSet="domainCategoryCompanyUrlsDataset" @csvExport="csvExport" @showSection="showSection" ref="searchSortFilterDomainCategoryCompanyUrlsTable" />

    </div>

    <div v-if="viewCompanyDetailsTable" id="company-details" class="container company-details" ref="company-details-table-header">

      <CompanyDetails :companyDetails="companyDetails" @csvExport="csvExport"></CompanyDetails>

    </div>

  </div>

</template>

<script>

  import LitepieDatepicker from 'litepie-datepicker'
  import '../assets/local.scss'
  import shared from './share.js'
  import SearchSortFilterTable from './SearchSortFilterTable.vue'
  import CompanyDetails from './CompanyDetails.vue'
  import {DomainCompanies} from "../classes/datasets/DomainCompanies";
  import {DomainUrls} from "../classes/datasets/DomainUrls";
  import {DomainCategories} from "../classes/datasets/DomainCategories";
  import {DomainUrlCompanies} from "../classes/datasets/DomainUrlCompanies";
  import {DomainCompanyUrls} from "../classes/datasets/DomainCompanyUrls";
  import {DomainCategoryUrls} from "../classes/datasets/DomainCategoryUrls";
  import {DomainCategoryCompanies} from "../classes/datasets/DomainCategoryCompanies";
  import {DomainCompanyCategories} from "../classes/datasets/DomainCompanyCategories";
  import {DomainCompanyCategoryUrls} from "../classes/datasets/DomainCompanyCategoryUrls";
  import {DomainCategoryCompanyUrls} from "../classes/datasets/DomainCategoryCompanyUrls";
  import {DomainCategoryUrlCompanies} from "../classes/datasets/DomainCategoryUrlCompanies";

  export default {
    name: 'AbmQueryTool',
    components: {
      CompanyDetails,
      LitepieDatepicker,
      SearchSortFilterTable
    },
    emits: ["expiredAuthToken"],
    data: () => ({
      // initial form bound models
      dataset: null,
      website: null,
      fetchedWebsite: null,
      websiteOptions: [
        'computing.co.uk',
        'channelweb.co.uk',
        'investmentweek.co.uk',
        'professionaladviser.com',
        'professionalpensions.com',
        'channelpartnerinsight.com',
        'crn.com',
        'techproviderzone.com',
        'securenetworkhub.com',
        'enterprisetechprovider.com',
        'theiotintegrator.com',
        'gochannelfirst.com',
        'hpezone.com',
      ],
      dateRange: { startDate: null, endDate: null },
      dateRangeFormatter: {date: 'DD-MM-YYYY', month: 'MMM'},

      // table data
      domainCompaniesDataset: null,
      domainUrlsDataset: null,
      domainUrlCompaniesDataset: null,
      domainCompanyUrlsDataset: null,
      domainCompanyCategoriesDataset: null,
      domainCompanyCategoryUrlsDataset: null,
      domainCategoriesDataset: null,
      domainCategoryUrlsDataset: null,
      domainCategoryUrlCompaniesDataset: null,
      domainCategoryCompaniesDataset: null,
      domainCategoryCompanyUrlsDataset: null,
      viewDomainUrlsTable: false,
      viewDomainUrlCompaniesTable: false,
      viewDomainCompanyUrlsTable: false,
      viewDomainCompaniesTable: false,
      viewDomainCategoriesTable: false,
      viewDomainCategoryUrlsTable: false,
      viewDomainCategoryUrlCompaniesTable: false,
      viewDomainCategoryCompaniesTable: false,
      viewDomainCategoryCompanyUrlsTable: false,
      viewDomainCompanyCategoriesTable: false,
      viewDomainCompanyCategoryUrlsTable: false,
      viewCompanyDetailsTable: false,
      selectedUrl: null,
      fetchingInitialResults: false,
      emptyInitialResultSet: false,
      emptyResultSetMessage: 'We\'re sorry, there were no results for your query.',
      companyDetails: {}
    }),

    computed: {

      fetchIsDisabled: function() {
        return this.dataset === null || // no dataset selected
          this.website === null || // no website selected
          this.dateRange.startDate === null || // no date range selected
          this.fetchingInitialResults; // we are already fetching results
      },

      sortedDomains: function() {
        return [...this.websiteOptions].sort((a,b) => {
          if(a < b) return -1;
          if(b > a) return 1;
          return 0;
        })
      }
    },

    methods: {

      async handleFetchClick(dataset) {

        // Set flag to true so we can disable additional button clicks while fetching
        this.fetchingInitialResults = true;
        this.emptyInitialResultSet = false;

        // Clear all results and start over with a new dataset
        this.clearResults();

        let results = await this.fetchResults(
          {domain: this.website, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          dataset
        );

        if (results.length === 0) {
          // No data. Set flag to false to re-enable button and display message.
          this.fetchingInitialResults = false;
          this.emptyInitialResultSet = true;
          return;
        }

        // Handle separate dataset selections
        if (dataset === 'urls') {
          this.domainUrlsDataset = new DomainUrls(results, this.website);
          await this.showTable('DomainUrls');
          this.scrollToElement('domain-urls-table-header');
        }
        if (dataset === 'companies') {
          this.domainCompaniesDataset = new DomainCompanies(results, this.website);
          await this.showTable('DomainCompanies');
          this.scrollToElement('domain-companies-table-header');
        }
        if (dataset === 'categories') {
          this.domainCategoriesDataset = new DomainCategories(results, this.website);
          await this.showTable('DomainCategories');
          this.scrollToElement('domain-categories-table-header');
        }

        // Set flag to false so we can re-enable button
        this.fetchingInitialResults = false;
      },

      async showSection(section, id, parent) {

        let childDataSets = parent.dataSet.getChildDataSets();

        if (section === 'companyDetails') {
          if (childDataSets.length === 1) {
            this.viewCompanyDetailsTable = false;
            this.viewDomainCompanyUrlsTable = false;
            this.viewDomainCompanyCategoriesTable = false;
            this.viewDomainCompanyCategoryUrlsTable = false;
          }
          this.handleCompanyDetailsClick();
        }

        if (section === 'domainCompanyUrls') {
          if (childDataSets.length === 1) {
            this.viewCompanyDetailsTable = false;
            this.viewDomainCompanyUrlsTable = false;
            this.viewDomainCompanyCategoriesTable = false;
            this.viewDomainCompanyCategoryUrlsTable = false;
          }
          this.handleDomainCompanyUrlsClick(id);
        }

        if (section === 'domainCategoryUrls') {
          if (childDataSets.length === 1) {
            this.viewCompanyDetailsTable = false;
            this.viewDomainCategoryUrlsTable = false;
            this.viewDomainCategoryCompaniesTable = false;
            this.viewDomainCategoryCompanyUrlsTable = false;
            this.viewDomainCategoryUrlCompaniesTable = false;
          }
          this.handleDomainCategoryUrlsClick(id);
        }

        if (section === 'domainCategoryCompanies') {
          if (childDataSets.length === 1) {
            this.viewCompanyDetailsTable = false;
            this.viewDomainCategoryUrlsTable = false;
            this.viewDomainCategoryCompaniesTable = false;
            this.viewDomainCategoryCompanyUrlsTable = false;
            this.viewDomainCategoryUrlCompaniesTable = false;
          }
          this.handleDomainCategoryCompaniesClick(id);
        }

        if (section === 'domainUrlCompanies') {
          if (childDataSets.length === 1) {
            this.viewDomainUrlCompaniesTable = false;
            this.viewCompanyDetailsTable = false;
          }
          this.handleDomainUrlCompaniesClick(id);
        }

        if (section === 'domainCompanyCategories') {
          if (childDataSets.length === 1) {
            this.viewCompanyDetailsTable = false;
            this.viewDomainCompanyUrlsTable = false;
            this.viewDomainCompanyCategoriesTable = false;
            this.viewDomainCompanyCategoryUrlsTable = false;
          }
          this.handleDomainCompanyCategoriesClick(id);
        }

        if (section === 'domainCompanyCategoryUrls') {
          if (childDataSets.length === 1) {
            this.viewDomainCompanyCategoryUrlsTable = false;
          }
          this.handleDomainCompanyCategoryUrlsClick(id);
        }

        if (section === 'domainCategoryCompanyUrls') {
          if (childDataSets.length === 1) {
            this.viewDomainCategoryCompanyUrlsTable = false;
          }
          this.handleDomainCategoryCompanyUrlsClick(id);
        }

        if (section === 'domainCategoryUrlCompanies') {
          if (childDataSets.length === 1) {
            this.viewDomainCategoryUrlCompaniesTable = false;
          }
          this.handleDomainCategoryUrlCompaniesClick(id);
        }
      },

      async handleDomainUrlCompaniesClick(url) {

        // Keep track of selected URL
        this.selectedUrl = url;

        // Fetch Companies and assign to data property
        let companies = await this.fetchResults(
          {domain: url, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'companies'
        );

        if (this.$refs.searchSortFilterDomainUrlsTable) {
          this.$refs.searchSortFilterDomainUrlsTable.finishedFetching();
        }

        if (companies.length === 0) {
          // No data
          this.domainUrlCompaniesDataset = {
            'message': {
              'title': 'Companies for ' + url,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainUrlCompaniesDataset = new DomainUrlCompanies(companies, this.website, url);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainUrlCompanies');

        // Scroll to the table
        this.scrollToElement('domain-url-companies-table-header');
      },

      async handleCompanyDetailsClick() {

        let dataset = null;
        if (this.domainCompaniesDataset) {
          dataset = this.domainCompaniesDataset;
        } else if (this.domainUrlCompaniesDataset) {
          dataset = this.domainUrlCompaniesDataset;
        } else if (this.domainCategoryCompaniesDataset) {
          dataset = this.domainCategoryCompaniesDataset;
        }

        this.companyDetails = dataset.getActiveRecordRow();

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('CompanyDetails');

        // Scroll to the details
        this.scrollToElement('company-details-table-header');

        if (this.$refs.searchSortFilterDomainCompaniesTable) {
          this.$refs.searchSortFilterDomainCompaniesTable.finishedFetching();
        }

        if (this.$refs.searchSortFilterDomainUrlCompaniesTable) {
          this.$refs.searchSortFilterDomainUrlCompaniesTable.finishedFetching();
        }

        if (this.$refs.searchSortFilterDomainCategoryCompaniesTable) {
          this.$refs.searchSortFilterDomainCategoryCompaniesTable.finishedFetching();
        }
      },

      async handleDomainCompanyUrlsClick(companyId) {

        // Fetch company URLs and assign to data property
        let companyUrls = await this.fetchResults(
          {domain: this.website, company: companyId, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'urls'
        );

        if (this.$refs.searchSortFilterDomainCompaniesTable) {
          this.$refs.searchSortFilterDomainCompaniesTable.finishedFetching();
        }

        if (companyUrls.length === 0) {
          // No data
          this.domainCompanyUrlsDataset = {
            'message': {
              'title': 'URLs for ' + this.website + ' - ' + this.domainCompaniesDataset.getActiveRecordRow().name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCompanyUrlsDataset = new DomainCompanyUrls(companyUrls, this.website, this.domainCompaniesDataset.getActiveRecordRow().name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCompanyUrls');

        // Scroll to the table
        this.scrollToElement('domain-company-urls-table-header');
      },

      async handleDomainCompanyCategoriesClick(companyName) {

        // Fetch company URLs and assign to data property
        let companyCategories = await this.fetchResults(
          {domain: this.website, company: companyName, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'categories'
        );

        if (this.$refs.searchSortFilterDomainCompaniesTable) {
          this.$refs.searchSortFilterDomainCompaniesTable.finishedFetching();
        }

        if (companyCategories.length === 0) {
          // No data
          this.domainCompanyCategoriesDataset = {
            'message': {
              'title': 'Categories for ' + this.website + ' - ' + this.domainCompaniesDataset.getActiveRecordRow().name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCompanyCategoriesDataset = new DomainCompanyCategories(companyCategories, this.website, this.domainCompaniesDataset.getActiveRecordRow().name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCompanyCategories');

        // Scroll to the table
        this.scrollToElement('domain-company-categories-table-header');

      },

      async handleDomainCompanyCategoryUrlsClick(category_key) {

        // Fetch company URLs and assign to data property
        let companyCategoryUrls = await this.fetchResults(
          {domain: this.website, company: this.domainCompaniesDataset.getActiveRecordRow().name, category: category_key, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'urls'
        );

        if (this.$refs.searchSortFilterDomainCompanyCategoriesTable) {
          this.$refs.searchSortFilterDomainCompanyCategoriesTable.finishedFetching();
        }

        let category_name = this.domainCompanyCategoriesDataset.getActiveRecordRow().parent;
        if (this.domainCompanyCategoriesDataset.getActiveRecordRow().child !== "") {
          category_name += ': ' + this.domainCompanyCategoriesDataset.getActiveRecordRow().child;
        }

        if (companyCategoryUrls.length === 0) {
          // No data
          this.domainCompanyCategoryUrlsDataset = {
            'message': {
              'title': 'URLs for ' + this.website + ' - ' + this.domainCompaniesDataset.getActiveRecordRow().name + ' - ' + category_name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCompanyCategoryUrlsDataset = new DomainCompanyCategoryUrls(companyCategoryUrls, this.website, this.domainCompaniesDataset.getActiveRecordRow().name, category_name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCompanyCategoryUrls');

        // Scroll to the table
        this.scrollToElement('domain-company-category-urls-table-header');

      },

      async handleDomainCategoryCompanyUrlsClick(companyName) {

        // Fetch company URLs and assign to data property
        let categoryCompanyUrls = await this.fetchResults(
          {domain: this.website, company: companyName, category: this.domainCategoriesDataset.getActiveRecordRow().key, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'urls'
        );

        if (this.$refs.searchSortFilterDomainCategoryCompaniesTable) {
          this.$refs.searchSortFilterDomainCategoryCompaniesTable.finishedFetching();
        }

        let category_name = this.domainCategoriesDataset.getActiveRecordRow().parent;
        if (this.domainCategoriesDataset.getActiveRecordRow().child !== "") {
          category_name += ': ' + this.domainCategoriesDataset.getActiveRecordRow().child;
        }

        if (categoryCompanyUrls.length === 0) {
          // No data
          this.domainCategoryCompanyUrlsDataset = {
            'message': {
              'title': 'URLs for ' + this.website + ' - ' + companyName + ' - ' + category_name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCategoryCompanyUrlsDataset = new DomainCategoryCompanyUrls(categoryCompanyUrls, this.website, companyName, category_name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCategoryCompanyUrls');

        // Scroll to the table
        this.scrollToElement('domain-category-company-urls-table-header');
      },

      async handleDomainCategoryUrlCompaniesClick(url) {

        // Keep track of selected URL
        this.selectedUrl = url;

        // Fetch Companies and assign to data property
        let companies = await this.fetchResults(
          {domain: url, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'companies'
        );

        if (this.$refs.searchSortFilterDomainCategoryUrlsTable) {
          this.$refs.searchSortFilterDomainCategoryUrlsTable.finishedFetching();
        }

        let category_name = this.domainCategoriesDataset.getActiveRecordRow().parent;
        if (this.domainCategoriesDataset.getActiveRecordRow().child !== "") {
          category_name += ': ' + this.domainCategoriesDataset.getActiveRecordRow().child;
        }

        if (companies.length === 0) {
          // No data
          this.domainCategoryUrlCompaniesDataset = {
            'message': {
              'title': 'Companies for ' + url + ' - ' + category_name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCategoryUrlCompaniesDataset = new DomainCategoryUrlCompanies(companies, this.website, category_name, url);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCategoryUrlCompanies');

        // Scroll to the table
        this.scrollToElement('domain-category-url-companies-table-header');
      },

      async handleDomainCategoryUrlsClick(category_key) {

        // Fetch category URLs and assign to data property
        let categoryUrls = await this.fetchResults(
          {domain: this.website, category: category_key, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'urls'
        );

        if (this.$refs.searchSortFilterDomainCategoriesTable) {
          this.$refs.searchSortFilterDomainCategoriesTable.finishedFetching();
        }

        let category_name = this.domainCategoriesDataset.getActiveRecordRow().parent;
        if (this.domainCategoriesDataset.getActiveRecordRow().child !== "") {
          category_name += ': ' + this.domainCategoriesDataset.getActiveRecordRow().child;
        }

        if (categoryUrls.length === 0) {
          // No data
          this.domainCategoryUrlsDataset = {
            'message': {
              'title': 'URLs for ' + this.website + ' - ' + category_name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCategoryUrlsDataset = new DomainCategoryUrls(categoryUrls, this.website, category_name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCategoryUrls');

        // Scroll to the table
        this.scrollToElement('domain-category-urls-table-header');

      },

      async handleDomainCategoryCompaniesClick(category_key) {

        // Fetch category companies and assign to data property
        let categoryCompanies = await this.fetchResults(
          {domain: this.website, category: category_key, daterange: this.dateRange.startDate + '_' + this.dateRange.endDate},
          'companies'
        );

        if (this.$refs.searchSortFilterDomainCategoriesTable) {
          this.$refs.searchSortFilterDomainCategoriesTable.finishedFetching();
        }

        let category_name = this.domainCategoriesDataset.getActiveRecordRow().parent;
        if (this.domainCategoriesDataset.getActiveRecordRow().child !== "") {
          category_name += ': ' + this.domainCategoriesDataset.getActiveRecordRow().child;
        }

        if (categoryCompanies.length === 0) {
          // No data
          this.domainCategoryCompaniesDataset = {
            'message': {
              'title': 'Companies for ' + this.website + ' - ' + category_name,
              'body': this.emptyResultSetMessage
            }
          };
        } else {
          this.domainCategoryCompaniesDataset = new DomainCategoryCompanies(categoryCompanies, this.website, category_name);
        }

        // Set visible. Await used so subsequent scrollToElement call succeeds
        await this.showTable('DomainCategoryCompanies');

        // Scroll to the table
        this.scrollToElement('domain-category-companies-table-header');

      },
      
      showTable(table) {
        this['view' + table + 'Table'] = true;
      },

      scrollToElement(element) {
        const el = this.$refs[element];

        if (el) {
          el.scrollIntoView({behavior: 'smooth'});
        }
      },

      async clearResults() {
        let that = this;
        await (function(){
          that.viewDomainCompaniesTable = false;
          that.viewDomainUrlsTable = false;
          that.viewDomainCategoriesTable = false;
          that.viewCompanyDetailsTable = false;
          that.viewDomainCompanyUrlsTable = false;
          that.viewDomainCompanyCategoriesTable = false;
          that.viewDomainCompanyCategoryUrlsTable = false;
          that.viewDomainCategoryUrlsTable = false;
          that.viewDomainCategoryCompaniesTable = false;
          that.viewDomainCategoryCompanyUrlsTable = false;
          that.viewDomainCategoryUrlCompaniesTable = false;
          that.viewDomainUrlCompaniesTable = false;
          that.emptyInitialResultSet = false;
          that.domainCompaniesDataset = null;
          that.domainUrlsDataset = null;
          that.domainUrlCompaniesDataset = null;
          that.domainCompanyUrlsDataset = null;
          that.domainCompanyCategoriesDataset = null;
          that.domainCompanyCategoryUrlsDataset = null;
          that.domainCategoriesDataset = null;
          that.domainCategoryUrlsDataset = null;
          that.domainCategoryCompaniesDataset = null;
          that.domainCategoryCompanyUrlsDataset = null;
          that.domainCategoryUrlCompaniesDataset = null;
        })();

        this.selectedUrl = null;
      },

      async fetchResults(parameters, path) {

        let url = '';

        if (window.location.hostname.indexOf('staging') !== -1) {
          url = new URL("https://s5q7peapri.execute-api.eu-west-2.amazonaws.com/staging/" + path)
        } else if (window.location.hostname.indexOf('localhost') !== -1) {
          url = new URL("http://127.0.0.1:8000/" + path);
        } else {
          url = new URL("https://xrmq3ziod7.execute-api.eu-west-2.amazonaws.com/dev/" + path);
        }
        url.search = new URLSearchParams(parameters);

        let start = performance.now();
        let results = await fetch(url.toString(), {
          headers: new Headers({
            'Authorization': this.$store.state.authToken
          })}
        ).then(async (response) => {
          if (parseInt(response.status) === 401) {
            /*
            The API Gateway will return a 401 unauthorized if the JWT Token has expired. If this happens, we need to
            emit this event to the parent so we can log out the user and show the login screen again.
            */
            this.$emit("expiredAuthToken", true);
            return [];
          }
          if ( ! response.ok) {

            return response.text().then(t => {
              let errorMessage = response.status.toString() + ': ' + response.statusText + ': ' + t;
              this.$store.dispatch('sendLogs', {'ErrorMessage': errorMessage, 'URL': url, 'Application': 'abm-client'});
              return {};
            });

          } else {
            return response.json().then(j => {
              if (Object.hasOwnProperty.call(j, 'payload') && Object.hasOwnProperty.call(j.payload, 'data') && j.payload.data.length === 0) {
                return {};
              }
              return j;
            });
          }
        }).catch((error) => {
          this.$store.dispatch('sendLogs', {'ErrorMessage': error.message, 'URL': url, 'Application': 'abm-client'});
          return {};
        });
        let end = performance.now();
        let totalFetchTime = end - start;
        this.$store.dispatch('sendLogs', {'FetchUrl': url.toString(), 'FetchTiming': totalFetchTime});

        return this.parseFetchedResponse(results);
      },

      parseFetchedResponse:function(results) {

        if ( ! Object.prototype.hasOwnProperty.call(results, 'payload')) {
          return [];
        }

        if (Object.prototype.hasOwnProperty.call(results['payload'], 'data')) {
          return results['payload']['data'];
        }

        let s3Url = results['payload']['s3'];

        return this.fetchFromS3(s3Url);
      },

      async fetchFromS3(s3Url) {
        let url = new URL(s3Url);

        return await fetch(url.toString())
          .then(async (response) => {
            if (parseInt(response.status) === 403) {
              /**
               * 403 Status Code is returned if link has expired
               */
              this.$store.dispatch('sendLogs', {'ErrorMessage': response.body, 'URL': url, 'Application': 'abm-client'});
              return [];
            }
            if (response.status >= 400 && response.status < 600) {
              let text = await response.text();
              this.$store.dispatch('sendLogs', {'ErrorMessage': text, 'URL': url, 'Application': 'abm-client'});
              return [];
            } else {
              return response.blob();
            }
          })
          .then(b => b.text())
          .then(t => JSON.parse(t))
          .catch((error) => {
            this.$store.dispatch('sendLogs', {'ErrorMessage': error.message, 'URL': url, 'Application': 'abm-client'});
            return [];
          });
      },

      async csvExport(data, type) {

        let originalName = this.determineCsvFileNameSource(type);
        let csvContent = this.createCsvContent(data, type);
        let fileName = '';
        if (type === 'company_details') {
          fileName = shared.createCsvFileName(originalName, type);
        } else {
          fileName = shared.createCsvFileName(originalName, type, this.dateRange);
        }

        shared.downloadCsv(encodeURI(csvContent), fileName);
      },

      determineCsvFileNameSource: function(type) {

        let originalName = '';
        switch (type) {
          case 'domain_urls':
            originalName = this.website;
            break;
          case 'domain_company_urls': case 'domain_company_categories':
            originalName = this.website + ' ' + this.domainCompaniesDataset.getActiveRecordRow().name;
            break;
          case 'company_details':
            if (this.domainCompaniesDataset) {
              originalName = this.domainCompaniesDataset.getActiveRecordRow().name;
            } else if (this.domainUrlCompaniesDataset) {
              originalName = this.domainUrlCompaniesDataset.getActiveRecordRow().name;
            } else if (this.domainCategoryCompaniesDataset) {
              originalName = this.domainCategoryCompaniesDataset.getActiveRecordRow().name;
            }
            break;
          case 'domain_companies': case 'domain_url_companies':
            if (this.selectedUrl) {
              originalName = this.selectedUrl;
            } else {
              originalName = this.website;
            }
            break;
          case 'domain_categories':
            originalName = this.website;
            break;
          case 'domain_category_urls':
            originalName = this.website + ' ' + this.domainCategoriesDataset.getActiveRecordRow().parent + ' ' + this.domainCategoriesDataset.getActiveRecordRow().child;
            break;
          case 'domain_company_category_urls':
            originalName = this.website + ' ' + this.domainCompaniesDataset.getActiveRecordRow().name + ' ' + this.domainCompanyCategoriesDataset.getActiveRecordRow().parent + ' ' + this.domainCompanyCategoriesDataset.getActiveRecordRow().child;
            break;
          case 'domain_category_company_urls':
            originalName = this.website + ' ' + this.domainCategoriesDataset.getActiveRecordRow().parent + ' ' + this.domainCategoriesDataset.getActiveRecordRow().child + ' ' + this.domainCategoryCompaniesDataset.getActiveRecordRow().name;
            break;
          case 'domain_category_url_companies':
            originalName = this.selectedUrl + ' ' + this.domainCategoriesDataset.getActiveRecordRow().parent + ' ' + this.domainCategoriesDataset.getActiveRecordRow().child;
            break;
          case 'domain_category_companies':
            originalName = this.website + ' ' + this.domainCategoriesDataset.getActiveRecordRow().parent + ' ' + this.domainCategoriesDataset.getActiveRecordRow().child;
            break;
        }

        return originalName;
      },

      createCsvContent: function(data, type) {

        let csvContent = "data:text/csv;charset=utf-8,";

        switch(type) {
          case 'company_details':
            csvContent += Object.keys(data).join(",");
            csvContent += "\n";
            csvContent += shared.cleanDataForCsv(data);
            break;
          case 'domain_urls': case 'domain_company_urls': case 'domain_companies': case 'domain_url_companies': case 'domain_category_urls': case 'domain_company_category_urls': case 'domain_category_company_urls': case 'domain_category_companies': case 'domain_category_url_companies':
            csvContent += Object.keys(data[0]).join(",");
            csvContent += "\n";
            for(let i=0;i<data.length;i++) {
              let cleaned = shared.cleanDataForCsv(data[i]);
              csvContent += cleaned;
              if (i !== data.length -1) {
                csvContent += "\n";
              }
            }
            break;
          case 'domain_categories': case 'domain_company_categories':
            data = data.map(v => {return {'parent': v.parent, 'child': v.child, 'type': v.type, 'urls': v.urls}});
            csvContent += Object.keys(data[0]).join(",");
            csvContent += "\n";
            for(let i=0;i<data.length;i++) {
              let cleaned = shared.cleanDataForCsv(data[i]);
              csvContent += cleaned;
              if (i !== data.length -1) {
                csvContent += "\n";
              }
            }
            break;
        }

        return csvContent;
      }

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.css"></style>

<style src="../../node_modules/vue-slider-component/theme/default.css"></style>
