<template>

    <div class="container">

        <div class="row" ref="url-table-header">

            <div class="col-sm-12 col-md-9 section-header">
                <h2>{{companyListName}} <font-awesome-icon v-if="fetching" :icon="['fas', 'spinner']" spin/></h2>
            </div>

        </div>

<!--        <div class="row">-->

<!--            <div class="col-sm-12 col-md-9">-->
<!--                <p>Total Companies in list: {{allCompanies.length}}</p>-->
<!--                <p>Companies with matches: {{allCompanies.filter(v => v.match_rate).length}}</p>-->
<!--                <p>Companies without matches: {{allCompanies.filter(v => ! (v.match_rate)).length}}</p>-->
<!--            </div>-->

<!--        </div>-->

        <div v-if="allCompanies.length" class="row" style="margin-bottom:20px">

            <div class="col-sm-12 col-md-3">

                <button
                    class="brand-button-3 view-section"
                    @click="viewCompanyListIpRanges()"
                    :class="{ 'view-section-inactive': ipRangeButtonInactive }">
                    Show IP Ranges
                </button>

            </div>

            <div class="col-sm-12 col-md-3">

                <button
                    class="brand-button-3 view-section"
                    @click="viewCompanyListFirmographics()"
                    :class="{ 'view-section-inactive': firmographicsButtonInactive }">
                    Show Firmographics
                </button>

            </div>

        </div>

        <div class="row">

            <div class="col-sm-12 col-md-6">
                <input
                    type="text"
                    id="companies-search-text"
                    v-model="companySearchText"
                    class="form-control"
                    :placeholder="'Search Companies ...'" />
            </div>

            <div id="visit-range-wrapper" class="col-sm-12 col-md-6">
                <div class="row slider-wrapper">
                    <div class="col-sm-6 slider-label">
                        Match Rate
                    </div>
                    <div class="col-sm-6 slider-values">
                        <span class="rangeValues">
                            {{matchRateLowerUpper[0] + ' - ' + matchRateLowerUpper[1]}}
                        </span>
                    </div>
                </div>
                <vue-slider :min="matchRateMin" :max="matchRateMax" v-bind="sliderOptions" v-model="matchRateLowerUpper" />
            </div>

        </div>

        <div class="table-responsive results-table-wrapper">

            <table id="company-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '30%'}"
                        @click="sortColumn('company')"
                        class="clickable"
                        :class="{ active: currentSort === 'company' }">Company</th>
                    <th :style="{ width: '30%'}"
                        @click="sortColumn('tradename')"
                        class="clickable"
                        :class="{ active: currentSort === 'tradename' }">Trade Name</th>
                    <th :style="{ width: '30%'}"
                        @click="sortColumn('website')"
                        class="clickable"
                        :class="{ active: currentSort === 'website' }">Domain</th>
                    <th :style="{ width: '10%'}"
                        @click="sortColumn('matchrate')"
                        class="clickable"
                        :class="{ active: currentSort === 'matchrate' }">Match Rate</th>
                </tr>
                </thead>
                <tbody id="company-list-tbody">
                <tr v-for="(company, i) in companies" v-bind:key="i">
                    <td>{{company.company}}</td>
                    <td>{{company.tradename || ''}}</td>
                    <td>{{company.website || ''}}</td>
                    <td>{{company.matchrate || ''}}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>

</template>


<script>

    import '../../../../assets/local.scss'
    import * as VueSlider from 'vue-slider-component'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faSpinner);

    export default {
        name: 'CompanyListView',
        props: {
            companyListId: String,
            companyListName: String,
        },
        emits: [
            "expiredAuthToken",
            "viewCompanyListIpRanges",
            "viewCompanyListFirmographics",
            "stopViewingCompanyListDetails",
            "fetchResults"
        ],

        components: {VueSlider, FontAwesomeIcon},

        async mounted() {
            this.fetchCompanyListResults();
        },

        watch: {
            companyListId: {
                handler: function(newValue) {
                    this.fetching = true;
                    this.fetchCompanyListResults(newValue);
                }
            },
            matchRateLowerUpper: {
                handler: function() {
                    this.stopViewingCompanyListDetails()
                }
            }
        },

        data: () => ({
            fetching: true,
            allCompanies: [],
            companySearchText: '',
            currentSortDir: 'asc',
            currentSort: 'company',
            sortedLists: {},
            matchRateMin: null,
            matchRateMax: null,
            matchRateLowerUpper: [0,100],
            sliderOptions: {
                processStyle: {backgroundColor: '#4d57c5'},
                dotStyle: {backgroundColor: 'white', borderColor: '#4d57c5'},
                tooltip: 'none'
            },
            ipRangeButtonInactive: false,
            firmographicsButtonInactive: false
        }),

        computed: {

            /*
            This computed function is called by Vue anytime any of its dependent values are updated. It will re-evaluate the
            list of companies to show to the user based on sorting, searching, or filtering of industry, revenue, or employees
            */

            companies: function() {
                let sorted = this.sortLists(this.allCompanies);
                return sorted.filter(company => {
                    let search = this.companySearchText.toLowerCase();
                    return search
                    ? (
                        (company.company && company.company.toLowerCase().includes(search))
                        ||
                        (company.tradename && company.tradename.toLowerCase().includes(search))
                        ||
                        (company.website && company.website.toLowerCase().includes(search))
                      )
                    : true;
                }).filter(company => {
                    return company.matchrate;
                }).filter(company => {
                    if (this.matchRateLowerUpper.length) {
                        return company.matchrate >= this.matchRateLowerUpper[0] && company.matchrate <= this.matchRateLowerUpper[1];
                    }
                    return true;
                })
            },
        },

        methods: {

            async fetchCompanyListResults() {

                this.clearResults();

                this.$emit("fetchResults", {'list-id': this.companyListId}, 'company-list', this.setAllCompanies);

                // Set up filtering options for tabular data
                this.determineFilterOptions();
            },

            setAllCompanies(companies) {
                this.fetching = false;
                this.allCompanies = companies;
            },

            sortLists(companies) {
                let sortedListKey = this.currentSort + '_' + this.currentSortDir;
                if (companies.length === 0) {
                    return [];
                }
                if ( ! (sortedListKey in this.sortedLists)) {
                    this.sortedLists[sortedListKey] = [...companies].sort((a,b) => {
                        let modifier = 1;
                        if(this.currentSortDir === 'desc') modifier = -1;
                        let cs = this.currentSort;
                        if (!a[cs] || !b[cs]) {
                            return 1;
                        }
                        let first = isNaN(a[cs]) ? a[cs].toLowerCase() : parseInt(a[cs], 10);
                        let second = isNaN(b[cs]) ? b[cs].toLowerCase() : parseInt(b[cs], 10);

                        if(first < second) return -1 * modifier;
                        if(first > second) return 1 * modifier;
                        return 0;
                    });
                }
                return this.sortedLists[sortedListKey];
            },

            sortColumn:function(newSortValue) {
                if(newSortValue === this.currentSort) {
                    // If user clicks on sortable column that is already highlighted, reverse sort order
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                } else {
                    this.currentSortDir = 'asc';
                }
                this.currentSort = newSortValue;
            },

            determineFilterOptions() {

                // Determine lower and upper bounds for match rate slider
                let matchRates = this.allCompanies.map(v => parseFloat(v.matchrate)).filter(v => v);
                let minMatchRate = matchRates.length ? Math.floor(Math.min.apply(Math, matchRates)) : 0;
                let maxMatchRate = matchRates.length ? Math.ceil(Math.max.apply(Math, matchRates)) : 100;
                this.matchRateLowerUpper = [minMatchRate, maxMatchRate];
                this.matchRateMin = minMatchRate;
                this.matchRateMax = maxMatchRate;
            },

            clearResults() {
                this.allCompanies = [];
                this.sortedLists = {};
                this.matchRateMin = null;
                this.matchRateMax = null;
                this.matchRateLowerUpper = [0,100];
                this.companySearchText = '';
                this.currentSortDir = 'asc';
                this.currentSort = 'company';
                this.firmographicsButtonInactive = false;
                this.ipRangeButtonInactive = false;
            },

            viewCompanyListIpRanges() {
                if (this.ipRangeButtonInactive) {
                    return false;
                }
                this.ipRangeButtonInactive = true;
                this.$emit("viewListIpRanges", {
                    name: this.companyListName, id: this.companyListId, matchRateRange: this.matchRateLowerUpper
                })
            },

            viewCompanyListFirmographics() {
                if (this.firmographicsButtonInactive) {
                    return false;
                }
                this.firmographicsButtonInactive = true;
                this.$emit("viewListFirmographics", {
                    name: this.companyListName, id: this.companyListId, matchRateRange: this.matchRateLowerUpper
                })
            },

            stopViewingCompanyListDetails() {
                this.ipRangeButtonInactive = false;
                this.firmographicsButtonInactive = false;
                this.$emit("stopViewingListDetails")
            }
        }
    }

</script>
