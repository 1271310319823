<template>

    <div class="container">

        <div class="row" ref="url-table-header">

            <div class="col-sm-12 col-md-9 section-header">
                <h2>{{companyListName}} Firmographics <font-awesome-icon v-if="fetching" :icon="['fas', 'spinner']" spin/></h2>
            </div>
            <div class="col-sm-12 col-md-3 section-header">
                <button
                        id="csv-export-companies"
                        class="btn btn-funnel-fuel float-end"
                        type="button"
                        @click="csvExport(companyListFirmographics, 'firmographics')">
                    <span>Download CSV</span>
                </button>
            </div>

        </div>

        <div class="table-responsive results-table-wrapper">

            <table id="company-list-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '20%'}">List Name</th>
                    <th :style="{ width: '20%'}">Trade Name</th>
                    <th :style="{ width: '15%'}">Domain</th>
                    <th :style="{ width: '15%'}">Employees</th>
                    <th :style="{ width: '15%'}">Revenue</th>
                    <th :style="{ width: '15%'}">Industry</th>
                </tr>
                </thead>
                <tbody id="company-list-tbody">
                <tr v-for="(company, i) in companyListFirmographics" v-bind:key="i">
                    <td>{{company.company}}</td>
                    <td>{{company.tradename}}</td>
                    <td>{{company.website}}</td>
                    <td>{{company.employees}}</td>
                    <td>{{company.revenue}}</td>
                    <td>{{company.sicgroup}}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>

</template>

<script>

    import '../../../../assets/local.scss'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faSpinner);

    export default {
        name: 'CompanyListFirmographics',
        props: {
            companyListId: String,
            companyListName: String,
            companyListMatchRateRange: Array,
        },
        components: {FontAwesomeIcon},
        emits: ["expiredAuthToken", "fetchResults", "csvExport"],

        async mounted() {
            this.fetchCompanyListFirmographics();
        },

        data: () => ({
            fetching: true,
            companyListFirmographics: [],
        }),

        methods: {

            async fetchCompanyListFirmographics() {
                this.$emit(
                    "fetchResults",
                    {
                        'list-id': this.companyListId,
                        'match-rate-range': this.companyListMatchRateRange[0] + '_' + this.companyListMatchRateRange[1]
                    },
                    'company-list-firmographics',
                    this.setCompanyListFirmographics
                );
            },

            setCompanyListFirmographics(firmographics) {
                this.fetching = false;
                this.companyListFirmographics = firmographics;
            },

            csvExport(data, type) {
                this.$emit('csvExport', this.companyListName, type, data)
            }
        }
    }

</script>
