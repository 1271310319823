"use strict";
exports.__esModule = true;
exports.MultiSelect = void 0;
var MultiSelect = /** @class */ (function () {
    function MultiSelect() {
        this.options = [];
        this.field = null;
    }
    MultiSelect.prototype.getOptions = function () {
        return this.options;
    };
    MultiSelect.prototype.setOptions = function (options) {
        this.options = options;
    };
    MultiSelect.prototype.getField = function () {
        return this.field;
    };
    MultiSelect.prototype.setField = function (field) {
        this.field = field;
    };
    return MultiSelect;
}());
exports.MultiSelect = MultiSelect;
