<template>

  <div class="row">
    <div class="col-sm-12 col-md-9 section-header">
      <h2 class="row">{{companyDetails.name}} - Details</h2>
    </div>
    <div class="col-sm-12 col-md-3 section-header">
      <button
          id="csv-export-company-details"
          class="btn btn-funnel-fuel float-end"
          type="button"
          @click="csvExport()">
        <span>Download CSV</span>
      </button>
    </div>
    <div class="col-sm-12 col-md-3">
      <h3 class="row">
        General
      </h3>
      <div class="row">
            <span class="company-details">
              Domains:
              <template v-for="(domain, index) in companyDetails.domains" :key="index">
                <div><a
                    :href="domain"
                    target="_blank"
                    class="company-link"
                >{{domain}}</a></div>
              </template>
            </span>
      </div>
      <div class="row">
        Employees:
        <template v-if="companyDetails.employees_upper">
          {{companyDetails.employees_lower}} - {{companyDetails.employees_upper}}
        </template>
        <template v-else>
          {{companyDetails.employees_lower}}+
        </template>
      </div>
      <div class="row">
        Revenue:
        <template v-if="companyDetails.revenue_upper">
          ${{companyDetails.revenue_lower}} - ${{companyDetails.revenue_upper}}
        </template>
        <template v-else>
          ${{companyDetails.revenue_lower}}+
        </template>
      </div>
    </div>
    <div class="col-sm-12 col-md-3">
      <h3 class="row">
        Social
      </h3>
      <template v-for="(social_platform, index) in companyDetails.social_platforms" :key="index">
        <div class="row">
            <span class="company-details">
              {{social_platform.platform}}
              <a
                  :href="'https://facebook.com/' + social_platform.url"
                  target="_blank"
                  class="company-link">
                {{social_platform.url}}
              </a>
            </span>
        </div>
      </template>
    </div>
    <div class="col-sm-12 col-md-3">
      <h3 class="row">
        Industry
      </h3>
      <h4 class="row">
        SIC
      </h4>
      <template v-for="(sic_code, index) in companyDetails.sic_codes" :key="index">
        <div class="row">
            {{sic_code.name}} ({{sic_code.code}})
        </div>
      </template>

      <h4 class="row">
        NAICS
      </h4>
      <template v-for="(naics_code, index) in companyDetails.naics_codes" :key="index">
        <div class="row">
          {{naics_code.name}} ({{naics_code.code}})
        </div>
      </template>
    </div>
  </div>

</template>

<script>
  export default {
    name: "CompanyDetails",

    props: ['companyDetails'],

    emits: ['csvExport'],

    methods: {

      // TODO test this component

      csvExport() {

        if (Object.prototype.hasOwnProperty.call(this.companyDetails, 'visits')) {
          delete this.companyDetails.visits;
        }

        this.$emit("csvExport", this.companyDetails, 'company_details')
      }

    }
  }
</script>

<style scoped>

</style>
