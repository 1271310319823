<template>

    <div class="container">

        <div class="row" v-if=" !(creatingList)" style="margin-bottom: 30px">

            <div class="col-sm-12 col-md-3">

                <button
                    class="brand-button-3 view-section"
                    @click="showCreateList()"
                    :class="{ 'view-section-inactive': creatingList }">
                    Create List
                </button>

            </div>

        </div>

        <span ref="list-create"></span>
        <ListCreate
                v-if="creatingList"
                @created="handleCreated"
                @cancelled="handleCancelled"
                @expiredAuthToken="signOut"></ListCreate>

        <span ref="list-view-all"></span>
        <ListViewAll
                v-if="viewingAllLists"
                ref="Lists"
                @showList="showList($event)"
                @expiredAuthToken="signOut"
                @fetchResults="fetchResults"
        ></ListViewAll>

        <span ref="company-list-view"></span>
        <CompanyListView
                v-if="viewingCompanyList"
                :company-list-id="viewedListId"
                :company-list-name="viewedListName"
                @viewListIpRanges="showListIpRanges($event)"
                @viewListFirmographics="showListFirmographics($event)"
                @stopViewingListDetails="stopViewingListDetails()"
                @expiredAuthToken="signOut"
                @fetchResults="fetchResults"
        ></CompanyListView>

        <span ref="company-list-ip-range-view"></span>
        <CompanyListIpRanges
                v-if="viewingCompanyListIpRanges"
                :list-id="viewedListId"
                :list-name="viewedListName"
                :list-match-rate-range="viewedListMatchRateRange"
                @expiredAuthToken="signOut"
                @fetchResults="fetchResults"
                @csvExport="csvExport"
        ></CompanyListIpRanges>

        <span ref="company-list-firmographics-view"></span>
        <CompanyListFirmographics
                v-if="viewingCompanyListFirmographics"
                :company-list-id="viewedListId"
                :company-list-name="viewedListName"
                :company-list-match-rate-range="viewedListMatchRateRange"
                @expiredAuthToken="signOut"
                @fetchResults="fetchResults"
                @csvExport="csvExport"
        ></CompanyListFirmographics>

        <span ref="domain-list-view"></span>
        <DomainListView
                v-if="viewingDomainList"
                :list-id="viewedListId"
                :list-name="viewedListName"
                @expiredAuthToken="signOut"
                @fetchResults="fetchResults"
                @csvExport="csvExport"
        ></DomainListView>

    </div>

</template>

<script>

    import '../../assets/local.scss'
    import ListViewAll from "./lists/ViewAll";
    import ListCreate from "./lists/Create";
    import CompanyListView from "./lists/company/View";
    import CompanyListIpRanges from "./lists/company/IpRanges";
    import CompanyListFirmographics from "./lists/company/Firmographics";
    import DomainListView from "./lists/domain/View"
    import shared from '../share.js'

    export default {
        name: 'Admin',
        props: {},
        components: {
            ListViewAll,
            ListCreate,
            CompanyListView,
            CompanyListIpRanges,
            CompanyListFirmographics,
            DomainListView
        },
        emits: ["expiredAuthToken"],

        data: () => ({
            allLists: [],
            viewedListId: null,
            viewedListName: null,
            creatingList: false,
            viewingAllLists: true,
            viewingCompanyList: false,
            viewingCompanyListIpRanges: false,
            viewingCompanyListFirmographics: false,
            viewingDomainList: false,
            viewedListMatchRateRange: null
        }),

        computed: {},

        methods: {

            scrollToElement(element) {
                const el = this.$refs[element];

                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
            },

            showCreateList() {
                this.creatingList = true;
                this.viewingAllLists = false;
                this.viewingCompanyList = false;
                this.viewingCompanyListIpRanges = false;
                this.viewingCompanyListFirmographics = false;
                this.viewingDomainList = false;
            },

            hideCreateList() {
                this.creatingList = false;
                this.viewingAllLists = true;
                this.viewingCompanyList = false;
                this.viewingCompanyListIpRanges = false;
                this.viewingCompanyListFirmographics = false;
                this.viewingDomainList = false;
            },

            handleCreated() {
                this.hideCreateList();
                this.$refs.Lists.fetchLists();
            },

            handleCancelled() {
                this.hideCreateList();
            },

            showList(ListData) {
                if (ListData.type == 'company') {
                    this.viewedListId = ListData.id;
                    this.viewedListName = ListData.name;
                    this.viewingDomainList = false;
                    this.viewingCompanyList = true;
                    this.viewingCompanyListIpRanges = false;
                    this.viewingCompanyListFirmographics = false;
                    let that = this;
                    window.setTimeout(function(){that.scrollToElement('company-list-view')}, 500);
                }
                if (ListData.type == 'domain') {
                    this.viewedListId = ListData.id;
                    this.viewedListName = ListData.name;
                    this.viewingDomainList = true;
                    this.viewingCompanyList = false;
                    this.viewingCompanyListIpRanges = false;
                    this.viewingCompanyListFirmographics = false;
                    let that = this;
                    window.setTimeout(function(){that.scrollToElement('domain-list-view')}, 500);
                }
            },

            showListIpRanges(ListData) {
                console.log('about to show list ranges with this data');
                console.log(ListData);

                this.viewedListId = ListData.id;
                this.viewedListName = ListData.name;
                this.viewedListMatchRateRange = ListData.matchRateRange;
                this.viewingCompanyListIpRanges = true;

                console.log('are we viewing ip ranges?')
                console.log(this.viewingCompanyListIpRanges);

                this.scrollToElement('company-list-ip-range-view');
            },

            showListFirmographics(ListData) {
                this.viewedListId = ListData.id;
                this.viewedListName = ListData.name;
                this.viewedListMatchRateRange = ListData.matchRateRange;
                this.viewingCompanyListFirmographics = true;
                this.scrollToElement('company-list-firmographics-view');
            },

            stopViewingListDetails() {
                this.viewingCompanyListIpRanges = false;
                this.viewingCompanyListFirmographics = false;
            },

            signOut() {
                this.$emit("expiredAuthToken", true);
            },

            async fetchResults(parameters, path, callback) {

                let url = '';

                if (window.location.hostname.indexOf('staging') !== -1) {
                    url = new URL("https://s5q7peapri.execute-api.eu-west-2.amazonaws.com/staging/" + path)
                } else if (window.location.hostname.indexOf('localhost') !== -1) {
                    url = new URL("http://127.0.0.1:8000/" + path);
                } else {
                    url = new URL("https://xrmq3ziod7.execute-api.eu-west-2.amazonaws.com/dev/" + path);
                }

                url.search = new URLSearchParams(parameters);

                let start = performance.now();
                let results = await fetch(url.toString(), {
                    headers: new Headers({
                        'Authorization': this.$store.state.authToken
                    })}
                ).then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        /*
                        The API Gateway will return a 401 unauthorized if the JWT Token has expired. If this happens, we need to
                        emit this event to the parent so we can log out the user and show the login screen again.
                        */
                        this.$emit("expiredAuthToken", true);
                        return [];
                    }
                    if (response.status >= 400 && response.status < 600) {
                        let text = await response.text();
                        this.$store.dispatch('sendLogs', {'ErrorMessage': text, 'URL': url, 'Application': 'abm-client'});
                        return [];
                    } else {
                        return response.json();
                    }
                }).catch((error) => {
                    this.$store.dispatch('sendLogs', {'ErrorMessage': error.message, 'URL': url, 'Application': 'abm-client'});
                    return [];
                });
                let end = performance.now();
                let totalFetchTime = end - start;
                this.$store.dispatch('sendLogs', {'FetchUrl': url.toString(), 'FetchTiming': totalFetchTime});

                callback(await this.parseFetchedResponse(results));
            },

            async parseFetchedResponse(results) {
              if (Object.prototype.hasOwnProperty.call(results['payload'], 'data')) {
                return results['payload']['data'];
              }

              let s3Url = results['payload']['s3'];

              let s3Result = await this.fetchFromS3(s3Url);

              return s3Result;
            },

            async fetchFromS3(s3Url) {
              let url = new URL(s3Url);

              return await fetch(url.toString())
                .then(async (response) => {
                  if (parseInt(response.status) === 403) {
                    /**
                     * 403 Status Code is returned if link has expired
                     */
                    this.$store.dispatch('sendLogs', {'ErrorMessage': response.body, 'URL': url, 'Application': 'abm-client'});
                    return [];
                  }
                  if (response.status >= 400 && response.status < 600) {
                    let text = await response.text();
                    this.$store.dispatch('sendLogs', {'ErrorMessage': text, 'URL': url, 'Application': 'abm-client'});
                    return [];
                  } else {
                    return response.blob();
                  }
                })
                .then(b => b.text())
                .then(t => JSON.parse(t))
                .catch((error) => {
                  this.$store.dispatch('sendLogs', {'ErrorMessage': error.message, 'URL': url, 'Application': 'abm-client'});
                  return [];
                });
            },

            async csvExport(entityName, type, data) {

                // Create CSV content and file name
                let csvContent = this.createCsvContent(data);
                let fileName = shared.createCsvFileName(entityName, type);

                shared.downloadCsv(encodeURI(csvContent), fileName);
            },

            createCsvContent(data) {

                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += Object.keys(data[0]).join(",");
                csvContent += "\n";
                for(let i=0;i<data.length;i++) {
                    let cleaned = shared.cleanDataForCsv(data[i]);
                    csvContent += cleaned;
                    if (i !== data.length -1) {
                        csvContent += "\n";
                    }
                }

                return csvContent
            }
        }
    }

</script>
