<template>

    <div class="container">

        <div class="row">

            <div class="col-sm-12 col-md-9 section-header">
                <h2>{{listName}} <font-awesome-icon v-if="fetching" :icon="['fas', 'spinner']" spin/></h2>
            </div>
            <div class="col-sm-12 col-md-3 section-header">
                <button
                        id="csv-export-domains"
                        class="btn btn-funnel-fuel float-end"
                        type="button"
                        @click="csvExport(domains.map(d => {return {'domain': d.domain, 'range': d.range}}), 'ip_ranges')">
                    <span>Download CSV</span>
                </button>
            </div>

        </div>

        <div class="table-responsive results-table-wrapper domain-list-ip-metadata">

            <table id="domain-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '25%' }">Original Domains</th>
                    <th :style="{ width: '25%' }">Unique Domains</th>
                    <th :style="{ width: '25%' }">% Recognized</th>
                    <th :style="{ width: '25%' }">% With IP Ranges</th>
                </tr>
                </thead>
                <tbody id="domain-list-tbody">
                    <tr>
                        <td>{{ allOriginalDomains.length }}</td>
                        <td>{{ allNormalizedDomains.length }}</td>
                        <td>{{ parseInt(([... new Set(enhancedDomains.map(d => d.domain))].length  / allNormalizedDomains.length).toFixed(2) * 100) || 0 }}</td>
                        <td>{{ parseInt(([... new Set(enhancedDomainsWithRanges.map(d => d.domain))].length / allNormalizedDomains.length).toFixed(2) * 100) || 0 }}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="table-responsive results-table-wrapper" id="domain-list-ips">

            <table id="domain-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '50%' }">Domain</th>
                    <th :style="{ width: '50%' }">IP Range</th>
                </tr>
                </thead>
                <tbody id="domain-list-tbody">
                <tr v-for="(domain, i) in domains" v-bind:key="i">
                    <td>{{ domain.domain }}</td>
                    <td>{{ domain.range }}</td>
                </tr>
                </tbody>
            </table>

        </div>

        <div v-if="enhancedDomainsWithoutRanges.length" class="row">

            <div class="col-sm-12 section-header">
                <h3>Domains With No Ranges</h3>
            </div>

        </div>

        <div v-if="enhancedDomainsWithoutRanges.length" class="table-responsive results-table-wrapper" id="domain-list-enhanced-domains-without-ranges">

            <table id="domain-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '50%' }">Domain</th>
                    <th :style="{ width: '50%' }">Status</th>
                </tr>
                </thead>
                <tbody id="enhanced-domains-without-ranges-tbody">
                <tr v-for="(domain, i) in enhancedDomainsWithoutRanges" v-bind:key="i">
                    <td>{{ domain.domain }}</td>
                    <td>No Ranges</td>
                </tr>
                </tbody>
            </table>

        </div>

        <div v-if="unenhancedDomains.length" class="row">

            <div class="col-sm-12 col-md-9 section-header">
                <h3>Domains We Have Never Enhanced</h3>
            </div>
            <div class="col-sm-12 col-md-3 section-header">
                <button
                        id="csv-export-domains"
                        class="btn btn-funnel-fuel float-end"
                        type="button"
                        @click="csvExport(unenhancedDomains.map(d => { return {'domain': d.domain} }), 'unenhanced_domains')">
                    <span>Download CSV</span>
                </button>
            </div>

        </div>

        <div v-if="unenhancedDomains.length" class="table-responsive results-table-wrapper" id="domain-list-unenhanced-domains">

            <table id="domain-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '50%' }">Domain</th>
                    <th :style="{ width: '50%' }">Status</th>
                </tr>
                </thead>
                <tbody id="domain-list-unenhanced-domains-tbody">
                <tr v-for="(domain, i) in unenhancedDomains" v-bind:key="i">
                    <td>{{ domain.domain }}</td>
                    <td>Never Enhanced</td>
                </tr>
                </tbody>
            </table>

        </div>

        <div v-if="allOriginalDomains.length" class="row">

            <div class="col-sm-12 section-header">
                <h3>Domain Normalization Results</h3>
            </div>

        </div>

        <div v-if="allOriginalDomains.length" class="table-responsive results-table-wrapper" id="domain-list-original-domains">

            <table id="domain-table" class="table table-striped">
                <thead>
                <tr>
                    <th :style="{ width: '50%' }">Original Domain</th>
                    <th :style="{ width: '50%' }">Normalized Domain</th>
                </tr>
                </thead>
                <tbody id="domain-list-original-domains-tbody">
                <tr v-for="(domain, i) in allOriginalDomains" v-bind:key="i">
                    <td>{{ domain.original }}</td>
                    <td>{{ domain.normalized }}</td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>

</template>


<script>

    import '../../../../assets/local.scss'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    library.add(faSpinner);

    export default {
        name: 'CompanyListView',
        props: {
            listId: String,
            listName: String,
        },
        emits: [
            "expiredAuthToken",
            "fetchResults"
        ],

        components: {
            FontAwesomeIcon
        },

        async mounted() {
            this.fetchDomainListResults();
        },

        watch: {
            listId: {
                handler: function(newValue) {
                    this.fetching = true;
                    this.clearResults();
                    this.fetchDomainListResults(newValue);
                }
            }
        },

        data: () => ({
            fetching: true,
            allNormalizedDomains: [],
            allOriginalDomains: [],
            enhancedDomainsWithRanges: [],
            enhancedDomainsWithoutRanges: [],
            enhancedDomains: [],
            unenhancedDomains: [],
            currentSortDir: 'asc',
            currentSort: 'domain',
            sortedLists: {}
        }),

        computed: {

            /*
            This computed function is called by Vue anytime any of its dependent values are updated. It will re-evaluate the
            list of companies to show to the user based on sorting, searching, or filtering of industry, revenue, or employees
            */

            domains: function() {
                return this.sortLists(this.enhancedDomainsWithRanges);
            },
        },

        methods: {

            async fetchDomainListResults() {

                this.clearResults();

                this.$emit("fetchResults", {'list-id': this.listId}, 'domain-list', this.setData);
            },

            setData(data) {
                this.fetching = false;
                this.allNormalizedDomains = [... new Set(data.normalized.map(d => d.domain))];
                this.allOriginalDomains = data.original;
                this.enhancedDomainsWithRanges = data.normalized.filter(x => x.range);
                this.enhancedDomainsWithoutRanges = data.normalized.filter(x => x.timestamp && ! x.range);
                this.enhancedDomains = data.normalized.filter(x => x.timestamp);
                this.unenhancedDomains = data.normalized.filter(x => ! x.timestamp);
            },

            sortLists(domains) {
                let sortedListKey = this.currentSort + '_' + this.currentSortDir;
                if (domains.length === 0) {
                    return [];
                }
                if ( ! (sortedListKey in this.sortedLists)) {
                    this.sortedLists[sortedListKey] = [...domains].sort((a,b) => {
                        let modifier = 1;
                        if(this.currentSortDir === 'desc') modifier = -1;
                        let cs = this.currentSort;
                        if (!a[cs] || !b[cs]) {
                            return 1;
                        }
                        let first = isNaN(a[cs]) ? a[cs].toLowerCase() : parseInt(a[cs], 10);
                        let second = isNaN(b[cs]) ? b[cs].toLowerCase() : parseInt(b[cs], 10);

                        if(first < second) return -1 * modifier;
                        if(first > second) return 1 * modifier;
                        return 0;
                    });
                }
                return this.sortedLists[sortedListKey];
            },

            sortColumn:function(newSortValue) {
                if(newSortValue === this.currentSort) {
                    // If user clicks on sortable column that is already highlighted, reverse sort order
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                } else {
                    this.currentSortDir = 'asc';
                }
                this.currentSort = newSortValue;
            },

            clearResults() {
                this.allNormalizedDomains = [];
                this.allOriginalDomains = [];
                this.enhancedDomainsWithRanges = [];
                this.enhancedDomainsWithoutRanges = [];
                this.unenhancedDomains = [];
                this.enhancedDomains = [];
                this.sortedLists = {};
                this.currentSort = 'domain';
            },

            csvExport(data, type) {
                this.$emit('csvExport', this.listName, type, data)
            }
        }
    }

</script>
