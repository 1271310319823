<template>
    <amplify-authenticator>
        <amplify-sign-in header-text="Please Sign In" slot="sign-in" :hide-sign-up="true" />

    </amplify-authenticator>

    <div v-if="authState === 'signedin' && user" class="container-fluid app-header">
        <div class="container">
            <div class="row">
                <div class="col-sm-7">
                    <img src="../public/images/funnel-fuel-logo.png" width="300"/>
                </div>
                <div class="col-sm-3 my-auto text-end">
                    {{user.username}}
                </div>
                <div class="col-sm-2">
                    <button id="sign-out-button" class="brand-button-3" @click="signOut">
                        SIGN OUT
                    </button>
                </div>
            </div>
            <div class="row">
                <span id="tool-description">Account-Based Marketing Query Tool</span>
            </div>
            <div id="section-options" class="row" v-if="isAdmin === true">
                <div class="col-sm-2">
                    <button
                            class="brand-button-3 view-section"
                            @click="toggleViewedSection('client')"
                            :class="{ 'view-section-inactive': sectionBeingViewed === 'admin' }">
                        Client View
                    </button>
                </div>
                <div class="col-sm-2">
                    <button
                            class="brand-button-3 view-section"
                            @click="toggleViewedSection('admin')"
                            :class="{ 'view-section-inactive': sectionBeingViewed === 'client' }">
                        Admin View
                    </button>
                </div>
            </div>
        </div>
    </div>

    <AbmQueryTool v-if="authState === 'signedin' && user && sectionBeingViewed === 'client'" @expiredAuthToken="signOut"/>
    <Admin v-if="authState === 'signedin' && user && sectionBeingViewed === 'admin'" @expiredAuthToken="signOut" />

</template>

<script>
import AbmQueryTool from './components/AbmQueryTool.vue'
import {onAuthUIStateChange} from "@aws-amplify/ui-components"
import { Auth, Hub} from "aws-amplify"
import Admin from "./components/admin/Admin"

export default {
    name: 'App',
    components: {
        Admin,
        AbmQueryTool,
    },
    created() {
        this.unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {
            this.authState = authState;

            if ( ! authData){
                return;
            }

            this.user = authData;

            // FunnelFuel is our admin group. If user is admin, we can show the admin sections
            // Our server authenticates admin routes against user being an admin, so there is no concern about this being
            // bypassed on the front end
            if ('cognito:groups' in this.user.signInUserSession.accessToken.payload
              && this.user.signInUserSession.accessToken.payload['cognito:groups'].includes('FunnelFuel')) {
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }

            this.$store.commit('authToken', authData.signInUserSession.idToken.jwtToken);

            this.$FullStory.identify(authData.attributes.sub, {
                displayName: authData.username,
                email: authData.attributes.email
            });
        });
    },
    data() {
        return {
            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined,
            sectionBeingViewed: 'client',
            isAdmin: false,
        };
    },
    beforeUnmount() {
        this.unsubscribeAuth();
    },
    methods: {
        async signOut() {
            await Auth.signOut();
            Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
                event: 'AuthStateChange',    // event must be 'AuthStateChange'
                message: 'signedout'    // message must be 'signedout'
            });
        },
        toggleViewedSection(section) {
            this.sectionBeingViewed = section
        },
    },
}
</script>
