import { createApp } from 'vue'
import { createStore } from 'vuex'

import App from './App.vue'
import './assets/tailwind.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Amplify, { Auth } from 'aws-amplify';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import * as FullStory from '@fullstory/browser';


// Configure Cognito
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-2_vHD6wlhOw',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '4tdnjcos4spfck1ocn5md5rh3m',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
});

Auth.configure();
applyPolyfills().then(() => {
  defineCustomElements(window);
});

// Set up FullStory recording
FullStory.init({ orgId: '13WPSH' });

const store = createStore({
  state: {
    authToken: ''
  },
  mutations: {
    authToken (state, authToken) {
      state.authToken = authToken;
    }
  },
  actions: {
    async sendLogs(state, logs) {
      // API Gateway w/ Auth -> Lambda to capture and print logs to Cloudwatch
      let url = "https://cwsquc10n2.execute-api.eu-west-2.amazonaws.com/prod/log";
      await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Authorization': this.state.authToken
        }),
        body: JSON.stringify(logs)
      });
    }
  }
});

const app = createApp(App);
app.config.isCustomElement = tag => tag.startsWith('amplify-');
app.config.globalProperties.$FullStory = FullStory;
app.use(store);
app.config.errorHandler = function (err) {
  store.dispatch('sendLogs', {'ErrorMessage': err.message, 'Application': 'abm-client'});
};
app.mount('#app');
