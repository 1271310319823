import { DataSet } from "../DataSet";
import { RangeFilter } from "../RangeFilter";
import { TableColumn } from "../TableColumn";
export class DomainCompanyUrls extends DataSet {
    constructor(resultSet, domain, company) {
        super();
        /**
         * Expected format for result set
         * [{"url": "<string>", "title": "<string>", "visits": "<string>"}]
         */
        this.urls = [];
        this.company = '';
        this.setResultSet(resultSet);
        this.setDomain(domain);
        this.setCompany(company);
        this.initialize();
    }
    initialize() {
        this.setDataTypeBasic('urls');
        this.setDataTypeFull('domain_company_urls');
        this.setDefaultSortColumn('visits');
        this.setDefaultSortDirection('desc');
        this.setUrls(this.getResultSet().filter(c => c.url !== ''));
        this.setTitle('URLs for ' + this.getDomain() + ' - ' + this.getCompany());
        this.setRowIdentifier('url');
        this.setIsSearchable(true);
        this.addSearchableField('url');
        this.createRangeFilters();
        this.createTableColumns();
        this.setTableRows(this.urls);
        this.setSearchClass('col-md-6');
        this.setRangeSliderClass('col-md-6');
    }
    createRangeFilters() {
        let min = Math.min.apply(Math, this.getUrls().map(c => c.visits));
        let max = Math.max.apply(Math, this.getUrls().map(c => c.visits));
        if (min === max) {
            return;
        }
        let filter = new RangeFilter();
        filter.setField('visits');
        filter.setMax(max);
        filter.setMin(min);
        this.addRangeFilter(filter);
    }
    createTableColumns() {
        let tableColumn = new TableColumn();
        tableColumn.setRowValueIsExternalClickable(true);
        tableColumn.setRowValueExternalClickableLinkRowValue('url');
        tableColumn.setRowValueTooltip('Visit');
        tableColumn.setRowValueClickIcon('external-link-alt');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderValue('title');
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setRowValue('title');
        this.addTableColumn(tableColumn);
        tableColumn = new TableColumn();
        tableColumn.setHeaderIsSortable(true);
        tableColumn.setHeaderValue('visits');
        tableColumn.setRowValue('visits');
        this.addTableColumn(tableColumn);
    }
    getUrls() {
        return this.urls.map(a => (Object.assign({}, a)));
    }
    setUrls(urls) {
        this.urls = urls;
    }
    getCompany() {
        return this.company;
    }
    setCompany(company) {
        this.company = company;
    }
}
